import pipe from '@/utils/pipe';

/**
 * @function youtubeWidthHeightEmbed
 * @description Sets the youtube width and height of an embed
 * @param {String} str The html embed string
 * @return {String}
 */
const youtubeWidthHeightEmbed = (str) => {
	const widthRegex = /width="?([0-9]+)"?/;
	const heightRegex = /height="?([0-9]+)"?/;
	const width = str.match(widthRegex);
	const height = str.match(heightRegex);
	let newString = str.slice(); // Create a copy of the str
	if (/youtube.com/i.test(str) === false) return str;
	if (width && typeof parseInt(width[1]) === 'number') {
		// We want to add to the str
		newString = newString.replace(widthRegex, `width="560"`);
	}
	if (height && typeof parseInt(height[1]) === 'number') {
		// We want to add to the str
		newString = newString.replace(heightRegex, `height="315"`);
	}
	return newString;
};

/**
 * @function youtubeGDPREmbed
 * @description Alters the iframe embed to be GDPR compliant
 * @param {String} str The html embed string
 * @return {String}
 */
const youtubeGDPREmbed = (str) => {
	const iframeSrcRegex = /\s(src=")/;
	const iframeEndRegex = /><\/iframe>/;
	const src = str.match(iframeSrcRegex);
	const end = str.match(iframeEndRegex);
	let newString = str.slice(); // Create a copy of the str
	if (/youtube.com/i.test(str) === false) return str;

	if (src) {
		// We want to modify the string
		newString = newString.replace(iframeSrcRegex, ' data-src="');
	}
	if (end) {
		newString = newString.replace(iframeEndRegex, ' class="optanon-category-4-8"></iframe>');
	}
	return newString;
};

const formatYoutubeEmbed = (str) => {
	const youtubePipeline = pipe(youtubeWidthHeightEmbed, youtubeGDPREmbed);
	return youtubePipeline(str);
};

export { formatYoutubeEmbed, youtubeWidthHeightEmbed, youtubeGDPREmbed };
