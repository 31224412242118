import React from 'react';
import { string } from 'prop-types';
import SvgImage from '@/components/icons/SvgImage';
import FBSharButton from '@/images/fb-share.svg';
import TWShareButton from '@/images/tw-share.svg';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import './SocialShare.scss';

/**
 * @const windowSize
 * @description Standardized social popup dimensions
 */
const windowSize = 'width=600,height=300';

/**
 * @const windowTarget
 * @description Open social popup in separate window
 */
const windowTarget = '_blank';

/**
 * @function SocialShare
 * @param {String} props.analyticsLinkLocation
 * @param {String} props.urlEncoded
 * @param {String} props.title
 * @returns {React.ReactElement}
 */
const SocialShare = ({ analyticsLinkLocation, urlEncoded, title, className }) => {
	const { translations } = usePageContext();

	return (
		<div className={`social-share ${className}`}>
			<button
				data-analytics-linkname="facebook-share-icon"
				data-analytics-linklocation={analyticsLinkLocation}
				className="social-share__fb-button social-share__button"
				onClick={() => {
					window.open(
						`https://www.facebook.com/sharer/sharer.php?u=${urlEncoded}%2F&amp;src=sdkpreparse`,
						windowTarget,
						windowSize
					);
				}}
			>
				<SvgImage className="social-share__fb-svg" image={FBSharButton} />
			</button>
			<button
				data-analytics-linkname="twitter-share-icon"
				data-analytics-linklocation={analyticsLinkLocation}
				className="social-share__tw-button social-share__button"
				onClick={() => {
					window.open(
						`https://twitter.com/intent/tweet?text=${encodeURIComponent(
							parseHtml(title)
						)}&url=${urlEncoded}&via=${translations.TWITTER_HANDLE}`,
						windowTarget,
						windowSize
					);
				}}
			>
				<SvgImage className="social-share__tw-svg" image={TWShareButton} />
			</button>
		</div>
	);
};

SocialShare.defaultProps = {
	className: ''
};

SocialShare.propTypes = {
	analyticsLinkLocation: string,
	urlEncoded: string,
	title: string,
	className: string
};

SocialShare.displayName = 'SocialShare';

export default SocialShare;
