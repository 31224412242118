/**
 * @function customShowBrandingCss
 * @description creates custom css string for show branding
 * @param {Object} show
 * @returns {String}
 */
export const customShowBrandingCss = (show) => {
	if (!show?.brandHexColor1) return '';
	const { brandHexColor1 } = show;
	return `
        .show-branding {
            --show-branding-color: ${brandHexColor1};
        }
        .show-social {
            --show-social-icons-color: ${brandHexColor1};
        }
    `;
};
