import React from 'react';
import TextOnly from './TextOnly';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { string } from 'prop-types';
import './CallToActionText.scss';

/**
 * @function CallToActionText
 * @description displays call to action segments with external links only
 * @param {String} text
 * @returns {React.ReactElement}
 */
const CallToActionText = ({ text }) => {
	if (!text || typeof text !== 'string') return null;
	return (
		<div
			className="adp__cta-text"
			data-analytics-linklocation="article-call-to-action-text"
			data-analytics-linkname={createAnalyticsString(striptags(text))}
		>
			<TextOnly text={text} />
		</div>
	);
};

CallToActionText.propTypes = {
	text: string.isRequired
};

CallToActionText.displayName = 'CallToActionText';

export default CallToActionText;
