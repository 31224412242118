import React, { Fragment, memo, useEffect, useState } from 'react';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { useArticleDetailContext } from '@/components/pages/article-detail/ArticleDetailContext';
import { array, bool } from 'prop-types';
import * as ComponentMap from './segment-mapping.data';
import WidgetTrending from '@/components/widgets/WidgetTrending';
import SegmentAds from '../article-ads/SegmentAds';
import { showAdAfterSegment, suppressSegmentAds, adSegmentCount } from '../article-ads/helpers';
import AmpTaboolaPageTracking from '@/components/pages/amp/ads/AmpTaboolaPageTracking.jsx';
import Taboola from '@/components/taboola/Taboola';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';

/**
 * @function getCleanSegments
 * @description cleans up segments with invalid data that are marked as valid on the back end
 * @description currently this pertains to PROMO_TUNE_IN and SHOW_TUNE_IN which can be valid even with no data
 * @description this occurs when a tunein is CCT is removed, but the tunein segment is still in the article
 * @description we have to clean up these empty segments to correctly display segment ads
 * @param {segments} segments
 * @returns {Array} - returns clean array of segments
 */
const getCleanSegments = (segments) => {
	return segments.filter(({ type, text }) => {
		return (
			(type !== 'PROMO_TUNE_IN' && type !== 'SHOW_TUNE_IN') ||
			(type === 'PROMO_TUNE_IN' && text) ||
			(type === 'SHOW_TUNE_IN' && text)
		);
	});
};

/**
 * @function Segments
 * @description take segments from database and delegates to the related component
 * @param {Object} props
 * @param {Boolean} [props.hasVerticalGallery]
 * @param {Array} props.segments - list of segments
 * @param {Boolean} [props.autoPlayTopVideo]
 * @returns {React.ReactElement}
 */
const Segments = ({ segments, hasVerticalGallery, autoPlayTopVideo }) => {
	const [breakpointWidth, setBreakpointWidth] = useState(null);

	// Get feature toggle to see if we use autoplay for vids
	const { PDK_AUTOPLAY_ENABLED } = useFeatureToggleContext();
	const cleanSegments = getCleanSegments(segments);
	// keeping suppressSegmentAds() out of the segments loop below,
	// it only needs to run once
	const suppressAds = suppressSegmentAds(cleanSegments);
	const totalSegments = cleanSegments.length;
	const { isAmp } = usePageContext();
	const { canonicalUrl, renderContextualWidget, hasContextualWidget } = useArticleDetailContext();
	const { TABOOLA_TRENDING_STORIES_PARAM } = useParamToggleContext();

	useEffect(() => {
		setBreakpointWidth(window.innerWidth >= '1024' ? 'desktop' : 'mobile');
	}, []);

	return (
		<Fragment>
			{cleanSegments.map((segment, index) => {
				const { type } = segment;
				const Component = ComponentMap[type];
				const includeSocialShare = index === 0 && !isAmp;
				const position = index + 1;
				return Component ? (
					<Fragment key={index}>
						<section className="article-detail__segment">
							<Component
								includeSocialShare={includeSocialShare}
								{...segment}
								segmentIndex={index}
								autoPlayTopVideo={
									index === 0 && autoPlayTopVideo && PDK_AUTOPLAY_ENABLED
								}
							/>
						</section>
						{isAmp && position === 2 && (
							<AmpTaboolaPageTracking canonicalUrl={canonicalUrl} />
						)}
						{position === 4 && position < totalSegments && (
							<section className="article-detail__segment is-hidden-desktop">
								{hasContextualWidget && renderContextualWidget ? (
									<div data-usc-contextual-recommends-widget></div>
								) : TABOOLA_TRENDING_STORIES_PARAM ? (
									breakpointWidth === 'mobile' && (
										<Taboola
											pageName="article"
											initData={[
												{
													placementName:
														'Mobile Mid Article - Content Recs',
													mode: 'organic-thumbnails-trending',
													instancesToGenerate: 1
												}
											]}
											generateContainers={true}
											isOnLoad={true}
										/>
									)
								) : (
									<WidgetTrending
										location="article-right-rail"
										count="3"
										taboolaHide={true}
									/>
								)}
							</section>
						)}
						{!suppressAds && showAdAfterSegment(index, type, totalSegments) ? (
							<SegmentAds
								hasVerticalGallery={hasVerticalGallery}
								count={adSegmentCount(index)}
							/>
						) : null}
					</Fragment>
				) : null;
			})}
		</Fragment>
	);
};

Segments.propTypes = {
	hasVerticalGallery: bool,
	autoPlayTopVideo: bool,
	segments: array.isRequired
};

Segments.displayName = 'Segments';

export default memo(Segments);
