import React from 'react';
import TextOnly from './TextOnly';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { string } from 'prop-types';
/**
 * @function A
 * @param {Object} props
 * @param {String} [props.href]
 * @return {React.ReactElement}
 */
const A = (props) => <a {...props} />;

A.displayName = 'A';

/**
 * @function Span
 * @param {Object} props
 * @return {React.ReactElement}
 */
const Span = ({ href, ...props }) => <span {...props} />;

Span.displayName = 'Span';

/**
 * @function TuneIn
 * @description displays tune in text
 * @param {String} text
 * @param {String} uri
 * @returns {React.ReactElement}
 */
const TuneIn = ({ text, uri }) => {
	if (!text) return null;
	const Wrap = !uri ? Span : A;
	return (
		<Wrap
			className="article-detail__tunein"
			href={uri}
			data-analytics-linklocation="article-text-block-tune-in"
			data-analytics-linkname={createAnalyticsString(striptags(text))}
		>
			<TextOnly text={text} />
		</Wrap>
	);
};

TuneIn.propTypes = {
	text: string.isRequired,
	uri: string
};

TuneIn.displayName = 'TuneIn';

export default TuneIn;
