/**
 * @function getTaboolaPlacement
 * @param {Object} settings
 * @param {Number} settings.taboolaCount
 * @param {Boolean} settings.hasVerticalGallery If its an ADP that has a vertical gallery
 * @param {Boolean} settings.isInVerticalGallery whether this segment block is a vertical gallery (used to differentiate the placement names)
 * @param {Boolean} settings.isAmp whether its in amp
 * @return {Object}
 */

const getTaboolaPlacement = ({ taboolaCount, isInVerticalGallery, hasVerticalGallery, isAmp }) => {
	if (taboolaCount === 1) {
		// If we're inside of a vertical gallery block it needs to have the Gallery Widget Name.
		// Otherwise, when there's any Vertical Gallery in an ADP all segments get the Vertical Gallery Placement name.
		// Normal ADPs get the Mid Article Thumbnail
		const placementName = isInVerticalGallery
			? 'Mid Article Thumbnail Vertical Gallery Widget'
			: null;

		return {
			placementName: isAmp
				? placementName
					? placementName + ' AMP'
					: placementName
				: placementName,
			mode: 'rec-reel-2n5-b'
		};
	}

	// Since we had to do a Mid Article Thumbnail slot we need to deduct one for the 1x1
	const oneByOneCount = taboolaCount - 1;
	// If we're inside of a vertical gallery block it needs to have the Gallery Widget Name. Otherwise, when there's any Vertical Gallery in an ADP all segments get the Vertical Gallery Placement name. Normal ADP's get the Mid Article Thumbnail
	const placementName = isInVerticalGallery
		? 'Mid Article Thumbnail 1x1 Vertical Gallery Widget'
		: null;

	// Any taboola ad after 1 gets the count suffix ex.
	// Mid Article Thumbnail then Mid Article Thumbnail 2
	const taboolaPlacementName = placementName
		? `${placementName} ${isAmp ? 'AMP' : ''} ${oneByOneCount > 1 ? oneByOneCount : ''}`
				.trim('')
				.replace(/\s+/g, ' ') // Remove double space with single;
		: placementName;

	return {
		placementName: taboolaPlacementName,
		mode: 'thumbs-feed-01-mid-article-v2'
	};
};

export default getTaboolaPlacement;
