import React from 'react';
import { IS_LOCAL, HOSTNAME_ALIAS } from '@/config/config';
import { string } from 'prop-types';
import { useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
const GAMP_HOSTNAME = IS_LOCAL ? 'http://127.0.0.1:4000' : HOSTNAME_ALIAS;

/**
 * @function AmpTaboolaPageTracking
 * @param {Object} props
 * @param {String} props.canonicalUrl
 * @return {React.ReactElement}
 */
const AmpTaboolaPageTracking = ({ canonicalUrl }) => {
	const { AMP_TABOOLA_PAGE_ENABLED } = useFeatureToggleContext();
	const { THIRD_PARTY_ENABLED, TABOOLA_AMP_OVERRIDE_ENABLED } = useParamToggleContext();
	if (
		THIRD_PARTY_ENABLED === false ||
		(!AMP_TABOOLA_PAGE_ENABLED && TABOOLA_AMP_OVERRIDE_ENABLED === false)
	)
		return null;
	return (
		<amp-iframe
			width="1px"
			height="1px"
			layout="fixed"
			sandbox="allow-scripts allow-same-origin"
			src={`${GAMP_HOSTNAME}/amp/tracking/taboola?canonicalUrl=${canonicalUrl}`}
		></amp-iframe>
	);
};

AmpTaboolaPageTracking.propTypes = {
	canonicalUrl: string.isRequired
};

AmpTaboolaPageTracking.displayName = 'AmpTaboolaPageTracking';

export default AmpTaboolaPageTracking;
