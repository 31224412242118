import format from 'date-fns/format';
import createAnalyticsString from '@/utils/createAnalyticsString';
import { formatCategoriesForAnalytics, formatAuthors } from '@/components/analytics/helpers';

/**
 * @function prepareContentData
 * @description takes data from article and converts it to be easily ingested into our analytics suite
 * @param {String} title
 * @param {Array} authors
 * @param {String} publishDate
 * @param {String} uri
 * @param {Array} categories
 * @param {Array} segments
 * @param {Object} show
 * @returns {Object}
 */
export const prepareContentData = ({
	title,
	authors,
	publishDate,
	uri,
	categories,
	segments,
	show
}) => {
	const textblockType = segments
		// creating an array using lowercase types
		.map((segment) => segment.type.toLowerCase())
		// removing duplicates
		.filter((type, index, arr) => arr.indexOf(type) === index)
		// joining types into a comma delimited string
		.join(',');

	return {
		title: createAnalyticsString(title),
		publishDate: format(new Date(publishDate), 'yyyy-dd-MM'),
		authors: formatAuthors(authors),
		originalUrl: `www.eonline.com${uri}`,
		categories: formatCategoriesForAnalytics(categories),
		contentType: show ? 'shows' : 'news',
		textblockType
	};
};

/**
 * @function createAnalyticsData
 * @description creates page specific analytics data for article detail
 * @param {Object} article full article data
 * @param {String} title
 * @param {Array} authors
 * @param {String} publishDate
 * @param {String} uri
 * @param {Array} categories
 * @param {Array} segments - textblocks
 * @param {Object} show - if the article is part of a show pacakge it will have data in the show object
 * @param {Object} branding - branding and sponsored info
 * @returns {Object}
 */
export const createAnalyticsData = (article) => {
	const {
		title,
		publishDate,
		authors,
		originalUrl,
		categories,
		contentType,
		textblockType
	} = prepareContentData(article);

	return {
		pageInfo: {
			siteSection: 'news',
			pageType: 'article-detail'
		},
		contentInfo: {
			title,
			publishDate,
			authors,
			originalUrl,
			categories,
			contentType,
			textblockType
		},
		sponsoredInfo: {
			advertiser: article?.branding?.adTracking?.advertiser || 'null'
		}
	};
};
