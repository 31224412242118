import React, { Fragment, useContext } from 'react';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import TextOnly from './TextOnly';
import PhotoOnly from './PhotoOnly';
import { object, number } from 'prop-types';
import customEventDispatcher from '@/components/analytics/customEventDispatcher';
import { useInView } from 'react-intersection-observer';
import SegmentAds from '../article-ads/SegmentAds';
import { suppressSegmentAds } from '../article-ads/helpers';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import { galleryLimit } from '@/components/pages/article-detail/articleDetailConstants.js';
import CallToAction from './CallToAction';

/* create a single custom event */
const dispatchVertGalleryPageViewEvent = customEventDispatcher(
	'adp-vertical-gallery-virtual-pageview'
);

/**
 * @function VertGalleryInViewTracker
 * @description will track using 'react-intersection-observer'
 * and dispatch events
 * @param {Object} children
 * @returns {React.ReactElement}
 */
const VertGalleryInViewTracker = ({ children }) => {
	const [vertGalleryImageRef, vertGalleryImageInView] = useInView({
		/* Optional options */
		threshold: 0.5,
		triggerOnce: true
	});

	if (
		vertGalleryImageInView &&
		dispatchVertGalleryPageViewEvent &&
		typeof window !== 'undefined'
	) {
		dispatchVertGalleryPageViewEvent();
	}

	return (
		<div className="vertical-gallery-image-ref" ref={vertGalleryImageRef}>
			{children}
		</div>
	);
};

VertGalleryInViewTracker.propTypes = {
	children: object.isRequired
};

VertGalleryInViewTracker.displayName = 'VertGalleryInViewTracker';

/**
 * @function showAdAfterPhoto
 * @description checks to see if there should be an ad after current photo
 * @param {Number} index - index of current photo
 * @param {Number} total - total number of photos
 * @returns {Boolean}
 */
export const showAdAfterPhoto = (index, total) => {
	// do not show ad after last photo
	if (index + 1 === total) return false;
	// show ad after every 3rd photo
	if ((index + 1) % 3 === 0) return true;
	// return false if nothing matches
	return false;
};

const adCount = (index) => (index + 1) / 3;

/**
 * @function VerticalGallery
 * @description displays a list of images with captions as a gallery
 * @param {Object} gallery
 * @returns {React.ReactElement}
 */
const VerticalGallery = ({ gallery, segmentIndex }) => {
	if (!gallery || segmentIndex === undefined) return null;
	const { translations } = usePageContext();
	const { segments } = useContext(ArticleDetailContext);
	const { galleryitems, uri, title, id, thumbnail } = gallery;
	const totalImages = galleryitems?.nodes?.length;
	const suppressAds = suppressSegmentAds(segments);

	const ctaData = {
		type: 'GALLERY',
		thumbnail,
		uri: `${uri}/page/2/#${galleryLimit + 1}`,
		title: `${translations.VIEW_MORE_PHOTOS_FROM} ${title}`,
		id
	};

	return galleryitems?.nodes?.length ? (
		<Fragment>
			{galleryitems.nodes.map(({ id, caption, image, title }, index) => {
				return (
					<Fragment key={`${id}-${index}`}>
						<figure
							className="article-detail__vgallery-item"
							data-testid="adp-vgallery-item"
						>
							<div data-testid="adp-vgallery-img">
								<VertGalleryInViewTracker>
									<PhotoOnly
										image={image}
										lazyLoad={segmentIndex > 0 || index > 0}
									/>
								</VertGalleryInViewTracker>
							</div>

							<figcaption>
								<div
									className="article-detail__vgallery-title"
									data-testid="adp-vgallery-title"
								>
									{parseHtml(title)}
								</div>
								<TextOnly text={caption} />
							</figcaption>
						</figure>
						{!suppressAds && showAdAfterPhoto(index, totalImages) ? (
							<div
								data-hook="vertical-gallery-ad"
								className="article-detail__vgallery-ad"
								data-testid="adp-vgallery-ad"
							>
								<SegmentAds
									hasVerticalGallery={true}
									isInVerticalGallery={true}
									count={adCount(index)}
								/>
							</div>
						) : null}
					</Fragment>
				);
			})}
			{galleryitems?.totalCount > galleryLimit && (
				<CallToAction
					cta={ctaData}
					linklocation="article-call-to-action-vertical-gallery-more-photos"
				/>
			)}
		</Fragment>
	) : null;
};

VerticalGallery.propTypes = {
	gallery: object.isRequired,
	segmentIndex: number.isRequired
};

VerticalGallery.displayName = 'VerticalGallery';

export default VerticalGallery;
