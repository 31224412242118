/**
 * @function getDurationFormat
 * @description formats a duration in seconds to
 * the required structured data format
 * @param {Number} seconds
 * @returns {Number}
 */
export const getDurationFormat = (seconds) => {
	return ('PT' + new Date(seconds * 1000).toISOString().substr(14, 5) + 'S').replace(':', 'M');
};
