import React from 'react';
import loadable from '@loadable/component';
import TextOnly from './TextOnly';
import PhotoText from './PhotoText';
import PhotoOnly from './PhotoOnly';
import VideoOnly from './VideoOnly';
import VideoText from './VideoText';
import CallToAction from './CallToAction';
import CallToActionText from './CallToActionText';
import VerticalGallery from './VerticalGallery';
import SocialContent from './SocialContent';
import Ecommerce from './Ecommerce';
import HtmlText from './HtmlText';
import TuneIn from './TuneIn';
import NbcuCheckoutItem from './NbcuCheckoutItem';
import CheckoutCollection from './CheckoutCollection';

const Loading = () => null;
const fallBack = { fallback: <Loading /> };

export const TEXT_ONLY = TextOnly;
export const PHOTO_TEXT = PhotoText;
export const PHOTO_ONLY = PhotoOnly;
export const VIDEO_ONLY = VideoOnly;
export const VIDEO_TEXT = VideoText;
export const CALL_TO_ACTION = CallToAction;
export const CALL_TO_ACTION_TEXT = CallToActionText;
export const VERTICAL_GALLERY = VerticalGallery;
export const SOCIAL_CONTENT = SocialContent;
export const ECOMMERCE = Ecommerce;
export const HTML_TEXT = HtmlText;
export const SHOW_TUNE_IN = TuneIn;
export const NBCU_CHECKOUT = NbcuCheckoutItem;
export const CHECKOUT_COLLECTION = CheckoutCollection;
export const PROMO_TUNE_IN = TuneIn;
export const POLL = loadable(() => import(/* webpackChunkName: "Polls" */ './polls/Polls'), {
	fallBack
});
