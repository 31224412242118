import React, { useContext, Fragment } from 'react';
import { PageContext } from '@/components/layout/page/PageContext';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import { ParamToggle } from '@/components/param-toggle/ParamToggleContext';
import { string } from 'prop-types';

/**
 * @function AmpTaboola
 * @param {Object} props
 * @param {String} props.placementName
 * @param {String} props.mode
 * @param {String} [props.heights]
 * @return {React.ReactElement}
 */
const AmpTaboola = ({ placementName, mode, heights }) => {
	const { edition } = useContext(PageContext);
	const { suppressSegmentAds } = useContext(ArticleDetailContext);
	const { THIRD_PARTY_ENABLED, TABOOLA_ENABLED } = useContext(ParamToggle);

	if (
		!placementName ||
		!mode ||
		suppressSegmentAds ||
		edition === 'CA' ||
		THIRD_PARTY_ENABLED === false ||
		TABOOLA_ENABLED === false
	)
		return null;

	return (
		<Fragment>
			<amp-embed
				width="100"
				height="100"
				type="taboola"
				layout="responsive"
				heights={heights}
				data-publisher="eonline-network"
				data-mode={mode}
				data-placement={placementName}
				data-target_type="mix"
				data-article="auto"
			></amp-embed>
		</Fragment>
	);
};

AmpTaboola.propTypes = {
	placementName: string.isRequired,
	mode: string.isRequired,
	heights: string
};

AmpTaboola.displayName = 'AmpTaboola';

export default AmpTaboola;
