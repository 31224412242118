/**
 * Helper functions for Article Detail Page
 */

// add to regex: /^(var|foo|bar)$/
const noAdsBelowList = /^(VERTICAL_GALLERY)$/;
const noAdsOnPageList = /^(ECOMMERCE|NBCU_CHECKOUT)$/;

/**
 * @function showAdAfterSegment
 * @description determins if we should show an ad after an article segment
 * @param {Number} index - index
 * @param {String} type - type of segment {PHOTO_ONLY, TEXT_ONLY}
 * @param {Number} totalSegments - total count of all segements in article
 * @returns {Boolean}
 */
export const showAdAfterSegment = (index, type, totalSegments) => {
	// do not show ad after last segment
	if (index + 1 === totalSegments) return false;
	// do not show any ads after 13th segment
	if (index + 1 >= 13) return false;
	// some text blocks should not have ads rigth after
	if (noAdsBelowList.test(type)) return false;
	// show ad after 2nd segment and every 3rd there after
	if ((index - 1) % 3 === 0) return true;
	// return false if nothing matches
	return false;
};

export const adSegmentCount = (index) => (index - 1) / 3 + 1;

/**
 * @function suppressSegmentAds
 * @description loops through segments array to look for
 * @description segments that require no ads on the page
 * @param {Array} segments - all segments in an article
 * @returns {Boolen}
 */
export const suppressSegmentAds = (segments) => {
	return segments && segments.some(({ type }) => noAdsOnPageList.test(type));
};
