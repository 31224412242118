import React, { Fragment } from 'react';
import { array } from 'prop-types';

/**
 * @function ArticleMetaAuthors
 * @description displays author name and links to author's page
 * @param {Array} authors - can be one or multiple authors
 * @param {Array} publishedByUsers - can be one or multiple authors
 * @returns {React.ReactElement}
 */
const ArticleMetaAuthors = ({ authors, publishedByUsers }) => {
	const allAuthors = [
		...(Array.isArray(authors) ? authors : []),
		...(Array.isArray(publishedByUsers) ? publishedByUsers : [])
	];
	if (!allAuthors) return;
	return (
		<Fragment>
			{allAuthors.map((author, index) => {
				const { id, fullName, uri } = author;
				const comma = index < allAuthors.length - 1 ? ',' : '';
				const spaceFront = index === 0 ? ' ' : '';
				const spaceBack = ' ';
				return (
					<span
						key={id}
						className="article-detail__meta__author"
						data-hook="adp-meta-author"
					>
						{spaceFront}
						<a href={uri}>{fullName}</a>
						{comma + spaceBack}
					</span>
				);
			})}
		</Fragment>
	);
};

ArticleMetaAuthors.propTypes = {
	authors: array,
	publishedByUsers: array
};

ArticleMetaAuthors.displayName = 'ArticleMetaAuthors';

export default ArticleMetaAuthors;
