import React from 'react';
import { object } from 'prop-types';
import striptags from 'striptags';
import createAnalyticsString from '@/utils/createAnalyticsString';
import parseHtml from '@/utils/parseHtml';
import TextOnly from './TextOnly';
import Thumb from '@/components/thumb/Thumb';
import { useArticleDetailContext } from '@/components/pages/article-detail/ArticleDetailContext';
import StructuredData from '@/components/structured-data/StructuredData';

/**
 * @function getAnalyticsData
 * @param {String} articleId
 * @param {Object} productOffer
 * @returns {Object} - with keys: analyticsDataBase, analyticsDataImg
 */
const getAnalyticsData = (articleId, productOffer) => {
	const { id, itemTitle, productLinks } = productOffer;

	return {
		analyticsDataBase: {
			'data-analytics-linklocation': 'article-text-block-ecommerce',
			'data-analytics-productid': id,
			'data-analytics-articleid': articleId,
			'data-analytics-productname': createAnalyticsString(striptags(itemTitle))
		},
		analyticsDataImg: {
			'data-analytics-linktype': 'image',
			'data-analytics-linkname': createAnalyticsString(productLinks[0]?.linkText || '')
		}
	};
};

/**
 * @function getStructuredPrice
 * @param {String} price
 * @returns {String}
 */
export const getStructuredPrice = (price) => {
	const defaultPrice = '0';
	const matches = price.match(/\$/);

	// if price string has no $ sign, return default price
	if (!matches?.[0]) return defaultPrice;

	// remove everything before and including dollar sign
	const structuredPrice = price.split('$')[1];

	// remove space, alphabetic letter, slash, or hyphen, and anything after that, from string
	return structuredPrice.split(/\s|[a-zA-Z]|\/|\-/)[0];
};

/**
 * @function Ecommerce
 * @description displays ecommerce text block
 * @param {Object} props
 * @param {Object} props.productOffer
 * @returns {React.ReactElement}
 */
const Ecommerce = ({ productOffer }) => {
	if (!productOffer) return null;

	const { articleId } = useArticleDetailContext();
	const { image, itemTitle, itemText, productLinks } = productOffer;
	const { analyticsDataBase, analyticsDataImg } = getAnalyticsData(articleId, productOffer);

	return (
		<div className="ecommerce" data-testid="ecommerce">
			<h3 data-hook="ecommerce-title" data-testid="ecommerce-title">
				{parseHtml(itemTitle)}
			</h3>
			<TextOnly text={itemText} />
			<div className="columns">
				<div className="column is-half">
					<Thumb
						analyticsData={{
							...analyticsDataBase,
							...analyticsDataImg,
							'data-testid': 'ecommerce-thumb-link'
						}}
						type="1x1"
						width="400"
						height="400"
						thumbnail={image}
						href={productLinks[0]?.linkUri || ''}
						target="_blank"
						rel="noopener"
					/>
				</div>
				<div className="column is-half">
					<div className="ecommerce__button-wrap">
						{productLinks.map(
							({ originalPrice, currentPrice, linkText, linkUri }, index) => {
								const analyticsDataButton = {
									'data-analytics-linktype': 'button',
									'data-analytics-linkname': createAnalyticsString(linkText)
								};

								// "noreferrer" is removed from Ecommerce rel property as per CWA-7735
								return (
									<a
										data-hook="ecommerce-link"
										data-testid="ecommerce-button-link"
										key={`ecommerce-button-${index}`}
										href={linkUri}
										className="ecommerce__button"
										{...analyticsDataBase}
										{...analyticsDataButton}
										// eslint-disable-next-line react/jsx-no-target-blank
										target="_blank"
										rel="noopener"
									>
										<div className="ecommerce__prices">
											<span
												data-hook="ecommerce-current-price"
												className="ecommerce__current-price"
											>
												{currentPrice}
											</span>
											{originalPrice && (
												<span
													data-hook="ecommerce-og-price"
													className="ecommerce__og-price"
												>
													{originalPrice}
												</span>
											)}
										</div>
										<div
											data-hook="ecommerce-button-text"
											className="ecommerce__button-text"
										>
											{linkText}
										</div>
									</a>
								);
							}
						)}
					</div>
				</div>
			</div>
			<StructuredData
				data={{
					'@type': 'Product',
					description: itemText,
					name: itemTitle,
					image: image.uri,
					offers: productLinks.map(({ currentPrice, linkUri }) => ({
						'@type': 'Offer',
						url: linkUri,
						price: getStructuredPrice(currentPrice),
						priceCurrency: 'USD'
					}))
				}}
			/>
		</div>
	);
};

Ecommerce.propTypes = {
	productOffer: object.isRequired
};

Ecommerce.displayName = 'Ecommerce';

export default Ecommerce;
