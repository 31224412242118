import React from 'react';
import TextOnly from './TextOnly';
import PhotoOnly from './PhotoOnly';
import { bool, object, string, number } from 'prop-types';

/**
 * @function PhotoText
 * @description displays both photo and text
 * @param {Object} props
 * @param {Object} props.image - image object from db
 * @param {Boolean} [props.includeSocialShare]
 * @param {String} props.text - text from cms, may have html formatting
 * @param {String} props.segmentIndex - text from cms, may have html formatting
 * @returns {React.ReactElement}
 */
const PhotoText = ({ image, includeSocialShare, text, segmentIndex }) => {
	if (!image || !text || segmentIndex === undefined) return null;
	const isPortrait = image.sourceHeight / image.sourceWidth > 1.2;
	const portraitClass = isPortrait ? 'article-detail__photo-text--portrait' : '';
	return (
		<div className={`article-detail__photo-text ${portraitClass}`}>
			<PhotoOnly
				image={image}
				includeSocialShare={includeSocialShare}
				lazyLoad={segmentIndex > 0}
			/>
			<TextOnly text={text} />
		</div>
	);
};

PhotoText.propTypes = {
	image: object.isRequired,
	text: string.isRequired,
	segmentIndex: number.isRequired,
	includeSocialShare: bool
};

PhotoText.displayName = 'PhotoText';

export default PhotoText;
