import React, { Fragment } from 'react';
import { object } from 'prop-types';
import striptags from 'striptags';
import { usePageContext } from '@/components/layout/page/PageContext';
import generateResizeLink from '@/utils/generateResizeLink';
import StructuredData from '@/components/structured-data/StructuredData';
import BreadcrumbList from '@/components/structured-data/BreadcrumbList';
import { useArticleDetailContext } from '@/components/pages/article-detail/ArticleDetailContext';
import { getDurationFormat } from '@/components/structured-data/structuredDataFormat';

/**
 * @function ArticleStructuredData
 * @description compiles the structured data for adp and amp
 * @param {Object} props
 * @param {Object} props.article
 * @param {Object} [props.imageObjectSize]
 * @returns {React.ReactElement}
 */
const ArticleStructuredData = ({ article, imageObjectSize }) => {
	if (!article) return null;

	const { authors, lastModDate, publishDate, pageMetaData, subhead, thumbnail, title } = article;
	const { segments } = useArticleDetailContext();
	const { path, translations } = usePageContext();

	const firstSegmentVideo = segments[0]?.video;
	const firstSegmentType = segments[0]?.type;

	const thumbUrlObj = generateResizeLink(
		thumbnail?.uri,
		imageObjectSize.imgW,
		imageObjectSize.imgH,
		true
	);

	const authorList = authors.map(item => ({'name': item.fullName}));

	const searchEngineStructuredData = {
		'@type': 'NewsArticle',
		mainEntityofPage: {
			'@type': 'WebPage',
			'@id': `https://www.eonline.com${path}`
		},
		headline: striptags(title),
		image: {
			'@type': 'ImageObject',
			url: thumbUrlObj?.src,
			width: imageObjectSize.imgW,
			height: imageObjectSize.imgH,
			caption: thumbnail?.title ? striptags(thumbnail.title) : ''
		},
		datePublished: publishDate,
		dateModified: lastModDate,
		author: authorList,
		publisher: {
			'@type': 'Organization',
			name: 'E! News',
			logo: {
				'@type': 'ImageObject',
				url:
					'https://akns-images.eonline.com/eol_images/Entire_Site/201709//rs_100x60-170109133805-e-news-amp-logo-black.png',
				width: 100,
				height: 60
			},
			description: striptags(subhead)
		}
	};

	const videoDescription =
		firstSegmentVideo?.description ||
		`${translations?.VIDEO_OBJECT_WATCH}${firstSegmentVideo?.title} ${translations?.VIDEO_OBJECT_ON_E}`;

	const videoStructuredData = firstSegmentVideo
		? {
				'@type': 'VideoObject',
				name: firstSegmentVideo?.title,
				duration: getDurationFormat(firstSegmentVideo?.duration),
				description: videoDescription,
				contentURL: firstSegmentVideo?.videoUri,
				thumbnailUrl: firstSegmentVideo?.thumbnail?.uri,
				uploadDate: firstSegmentVideo?.publishDate,
				expires: firstSegmentVideo?.expirationDate || undefined
		  }
		: null;

	return (
		<Fragment>
			<BreadcrumbList landingName="News" detailUrl={pageMetaData?.canonicalUrl} />
			<StructuredData data={searchEngineStructuredData} />
			{(firstSegmentType === 'VIDEO_ONLY' || firstSegmentType === 'VIDEO_TEXT') &&
			videoStructuredData ? (
				<StructuredData data={videoStructuredData} />
			) : null}
		</Fragment>
	);
};

ArticleStructuredData.defaultProps = {
	imageObjectSize: {
		imgW: 1200,
		imgH: 1200
	}
};

ArticleStructuredData.propTypes = {
	article: object.isRequired,
	imageObjectSize: object
};

ArticleStructuredData.displayName = 'ArticleStructuredData';

export default ArticleStructuredData;
