import React from 'react';
import { object, string } from 'prop-types';
import Translate from '@/components/i18n/Translate';
import Thumb from '@/components/thumb/Thumb';
import parseHtml from '@/utils/parseHtml';
import isPlainObject from 'lodash-es/isPlainObject';
import ContentItemIcon from '@/components/icons/ContentItemIcon';

const titleTranslateMap = {
	ARTICLE: 'READ',
	GALLERY: 'PHOTOS',
	VIDEO: 'WATCH'
};

/**
 * @function CallToAction
 * @description displays call to action segment, which can be video, article, gallery
 * @param {Object} cta - cta object
 * @param {String} linklocation - value for analytics data attribute
 * @returns {React.ReactElement}
 */
const CallToAction = ({ cta, linklocation }) => {
	if (!cta || !isPlainObject(cta)) return null;

	const { type, thumbnail, uri, title, id } = cta;

	return (
		<div className="article-detail__cta" data-testid="segment-cta">
			<a
				data-hook="cta-link"
				href={uri}
				data-analytics-linklocation={linklocation}
				data-analytics-cta-type={type.toLowerCase()}
			>
				<div className="article-detail__cta__thumb-wrap">
					<Thumb
						type="1x1"
						width="100"
						height="100"
						thumbnail={thumbnail}
						withLink={false}
						videoId={type === 'VIDEO' ? id : null}
						className="article-detail__image-wrap"
					>
						<ContentItemIcon type={type} />
					</Thumb>
				</div>
				<div className="article-detail__cta__body">
					<div className="article-detail__cta__heading">
						<Translate word={titleTranslateMap[type]} />
					</div>
					<div data-hook="cta-title" className="article-detail__cta__title">
						{parseHtml(title)}
					</div>
				</div>
			</a>
		</div>
	);
};

CallToAction.defaultProps = {
	linklocation: 'article-call-to-action'
};

CallToAction.propTypes = {
	cta: object.isRequired,
	linklocation: string
};

CallToAction.displayName = 'CallToAction';

export default CallToAction;
