import React, { useContext } from 'react';
import { string } from 'prop-types';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import nanoid from 'nanoid';
/**
 * @function AmpAd
 * @description renders amp-ad element
 * @description requires a parrent amp-list element to populate 'data-slot': '{{slot}}'
 * @param {String} width - desired ad width
 * @param {String} height - desired ad height
 * @param {String} [multiSize] data-multi-size , must be different size from width x height
 * @param {String} slotname - for rtc config url
 * @param {String} [refresh] data-multi-size , refresh interval for ad
 * @returns {React.ReactElement}
 */
const AmpAd = ({ width, height, multiSize, slotname, refresh }) => {
	const { uri, categories } = useContext(ArticleDetailContext);

	if (!uri || !categories || !width || !height || !slotname) return null;

	const catNames = categories
		.filter(({ displayName }) => displayName)
		.map(({ displayName }) => displayName.replace(' ', '+'));
	const catString = catNames.length ? `|${catNames.join('|')}` : '';
	const urls = `https://mps.nbcuni.com/request/page/json/amp?path=${uri}&site=e-web&type=amp-article&cat=amp|news${catString}&slotname=${slotname}&cag[slot]=${slotname}&adunit=${slotname}`;

	const ampAdProps = {
		width: width,
		height: height,
		'data-multi-size-validation': 'false',
		'data-loading-strategy': 'prefer-viewability-over-views',
		'rtc-config': `{
			"urls": [
				"${urls}"
			],
			"vendors": {
				"aps": {"PUB_ID":"3219","PARAMS":{"amp":"1"}},
				"prebidrubicon": {
					"REQUEST_ID": "24610-amp-eonline-us",
					"ACCOUNT_ID": "24610"
				}
			}
		}`,
		type: 'doubleclick',
		'data-slot': '{{slot}}'
	};

	if (refresh) ampAdProps['data-enable-refresh'] = refresh;
	if (multiSize) ampAdProps['data-multi-size'] = multiSize;

	return <amp-ad id={slotname + '-' + nanoid()} {...ampAdProps}></amp-ad>;
};

AmpAd.propTypes = {
	width: string.isRequired,
	height: string.isRequired,
	slotname: string.isRequired,
	multiSize: string,
	refresh: string
};

AmpAd.displayName = 'AmpAd';

export default AmpAd;
