import { gql } from '@apollo/client';

export default gql`
	query VIDEO_FEED_BY_ID($id: ID!, $edition: EDITIONKEY!, $limit: Int!, $skip: Int!) {
		videoFeed(id: $id, edition: $edition, limit: $limit, skip: $skip) {
			id
			videos {
				id
				publicId
				title
				videoUri
				duration
				description
				publishDate
				expirationDate
				webVttUri
				thumbnail {
					id
					title
					uri
				}
				hdThumbnail {
					id
					title
					uri
				}
			}
		}
	}
`;
