import React, { Fragment, useContext } from 'react';
import { PageContext } from '@/components/layout/page/PageContext';
import { Helmet } from 'react-helmet-async';
import { object } from 'prop-types';
import parseHtml from '@/utils/parseHtml';
import Thumb from '@/components/thumb/Thumb';
import { customShowBrandingCss } from './helpers';
import ShowSocialIcons from './ShowSocialIcons';
import './ShowBranding.scss';

/**
 * @function ShowBranding
 * @description provides a Show Branding element if show object with brandingImage is available
 * @param {Object} [props.show]
 * @returns {React.ReactElement|null}
 */
const ShowBranding = ({ show }) => {
	const { isAmp } = useContext(PageContext);

	if (!show?.logo) return null;

	const { uri, tuneIn, logo } = show;

	// This style constrains the wrapping div to the source dimension to avoid pixellation
	const imgContainerStyle = { width: `${logo?.sourceWidth}px` };

	return (
		<Fragment>
			{!isAmp && (
				<Helmet>
					<style type="text/css">{customShowBrandingCss(show)}</style>
				</Helmet>
			)}
			<div data-hook="show-branding" className="show-branding">
				{/* Content Container */}
				<div className="show-branding__container columns is-mobile is-centered">
					{/* Branding Logo */}
					<div className="show-branding__logo column is-12-mobile is-narrow-tablet">
						<div className="show-branding__img-container" style={imgContainerStyle}>
							<Thumb href={uri} thumbnail={logo} withLink={true} />
						</div>
					</div>

					{/* Tune In */}
					<div className="show-branding__tunein column">{parseHtml(tuneIn)}</div>

					{/* Social Icons */}
					<ShowSocialIcons
						show={show}
						className="show-branding"
						analyticsLinkNameSuffix="-show-nav"
						analyticsLinkLocation="gallery-detail-page"
					/>
				</div>
			</div>
		</Fragment>
	);
};

ShowBranding.propTypes = {
	show: object
};

ShowBranding.displayName = 'ShowBranding';

export default ShowBranding;
