import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import striptags from 'striptags';
import ARTICLE from '@/graphql/queries/ARTICLE';
import ARTICLE_PREVIEW from '@/graphql/queries/ARTICLE_PREVIEW';
import HrefLang from '@/components/i18n/hreflang/HrefLang';
import { MpsDataConfig } from '@/components/mps/MpsConfig';
import DataLayer from '@/components/analytics/DataLayer';
import ArticleMeta from './article-meta/ArticleMeta';
import Segments from './segments/Segments';
import parseHtml from '@/utils/parseHtml';
import WidgetTrending from '@/components/widgets/WidgetTrending';
import LatestNews from './LatestNews';
import ShowBranding from '@/components/show-branding/ShowBranding';
import SnipeBanner from '@/components/snipe-banner/SnipeBanner';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import { createAnalyticsData } from './article-analytics';
import ArticleDetailContext from './ArticleDetailContext';
import Taboola from '@/components/taboola/Taboola';
import Loading from '@/components/loading/Loading';
import { queryError } from '@/utils/errorHandling';
import ArticleHead from './ArticleHead';
import MpsAd from '@/components/mps/MpsAd';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { galleryLimit } from './articleDetailConstants';
import { IS_PREVIEW_MODE } from '@/config/config';
import { newsroom } from '@/components/taboola/taboola-scripts';
import NielsenStaticDCR from '@/components/nielsen/NielsenStaticDCR';
import WellsFargoAd from '@/components/ads/wells-fargo/WellsFargoAd';
import './ArticleDetail.scss';

const analyticsLinkLocation = 'article-detail-page';

/**
 * @function ArticleDetail
 * @description Page that displays info for articles
 * @param {Object} props
 * @returns {React.ReactElement}
 */
const ArticleDetail = () => {
	const {
		articleId,
		articleTitle,
		uppercaseEdition,
		edition,
		isAmp,
		checkoutResponse
	} = usePageContext();
	const {
		THIRD_PARTY_ENABLED,
		TABOOLA_ENABLED,
		CONTEXTUAL_WIDGET_PARAM,
		WELLS_FARGO_PARAM,
		CHECKOUT_TRACKING_PARAM,
		TABOOLA_TRENDING_STORIES_PARAM
	} = useParamToggleContext();
	const { CONTEXTUAL_WIDGET, WELLS_FARGO } = useFeatureToggleContext();
	let hasContextualWidget = false;

	const [renderContextualWidget, updateRenderContextualWidget] = useState(true);
	const [breakpointWidth, setBreakpointWidth] = useState(null);

	const disableTaboola =
		uppercaseEdition === 'CA' || THIRD_PARTY_ENABLED === false || TABOOLA_ENABLED === false;
	const query = IS_PREVIEW_MODE ? ARTICLE_PREVIEW : ARTICLE;
	const source = IS_PREVIEW_MODE ? { source: 'SAVED' } : {};
	const { data, error, loading } = useQuery(query, {
		variables: {
			id: articleId,
			edition: uppercaseEdition,
			sanitizer: null,
			limit: galleryLimit,
			...source
		}
	});
	const hasCheckoutCollection =
		data?.article?.segments.filter(({ type }) => type === 'CHECKOUT_COLLECTION').length > 0 ||
		false;
	const trackCheckoutCollection = CHECKOUT_TRACKING_PARAM;

	useEffect(() => {
		setBreakpointWidth(window.innerWidth >= '1024' ? 'desktop' : 'mobile');
	}, []);

	useEffect(() => {
		if (
			hasCheckoutCollection &&
			window !== 'undefined' &&
			window.uscWidget?.showEmbeddableProductsWidget
		) {
			window.uscWidget.showEmbeddableProductsWidget();
			if (trackCheckoutCollection) window.uscWidget.rescan();
		}
		if (!disableTaboola) newsroom();

		if (hasContextualWidget && checkoutResponse.loaded) {
			const err = checkoutResponse.err;
			if (typeof window?.uscWidget?.showRecommendations === 'undefined' || err) {
				// hide contextual widget if it can't load
				updateRenderContextualWidget(false);
				if (err) console.log(err);
			} else {
				window.uscWidget
					.showRecommendations({ limit: 10 })
					.then((result) => {
						if (!result?.productsCount) updateRenderContextualWidget(false);
					})
					.catch((err) => {
						console.log('Show Recommendations error:', err);
						updateRenderContextualWidget(false);
					});
			}
		}
	}, [
		disableTaboola,
		hasContextualWidget,
		updateRenderContextualWidget,
		checkoutResponse,
		hasCheckoutCollection,
		trackCheckoutCollection
	]);

	if (loading) return <Loading />;
	if (error) console.log('error in Article Detail query:', error.message);
	if (!data?.article) {
		throw queryError({ message: 'Article Not Found', pageType: 'ADP', id: articleId });
	}

	const article = data.article;
	const blockTaboola = /^(CA)$/i.test(uppercaseEdition);

	const {
		title,
		subhead,
		authors,
		publishedByUsers,
		translators,
		publishDate,
		segments,
		show,
		branding,
		uri,
		categories,
		pageMetaData,
		enableVideoPlaylist,
		snipe,
		firstPublishDate
	} = article;

	const redirectUrl = pageMetaData?.redirectUrl;
	const hasVerticalGallery =
		segments.filter(({ type }) => type === 'VERTICAL_GALLERY').length > 0;
	const hasEcommerce = segments.filter(({ type }) => type === 'ECOMMERCE').length > 0;
	const hasCheckout = segments.filter(({ type }) => type === 'NBCU_CHECKOUT').length > 0;
	const hasShoppingCategory =
		(hasEcommerce || hasCheckout) &&
		uppercaseEdition === 'US' &&
		categories.filter(({ name }) => name === 'Shopping').length > 0;
	const belowArticleThumbPlacementName = hasShoppingCategory
		? 'Below Article Thumbnails v2 - Shopping'
		: hasVerticalGallery
		? 'Below Article Thumbnails Vertical Gallery v2'
		: 'Below Article Thumbnails v2';
	const belowArticleThumbContainerName = belowArticleThumbPlacementName
		.toLocaleLowerCase()
		.replace(/ /g, '-');
	const hasShopCategory =
		hasEcommerce && categories.filter(({ name }) => name === 'shop').length > 0;
	hasContextualWidget =
		CONTEXTUAL_WIDGET_PARAM ||
		(!isAmp &&
			CONTEXTUAL_WIDGET &&
			categories.filter(({ key }) => key === 'nbcu_checkout').length > 0);
	const showWellsFargoAd =
		(hasCheckout || hasCheckoutCollection) &&
		uppercaseEdition === 'US' &&
		(WELLS_FARGO || WELLS_FARGO_PARAM);

	if (redirectUrl) {
		throw queryError({
			id: articleId,
			message: 'Page Moved Permanently',
			pageType: 'ArticleDetail',
			redirectUrl,
			status: 301
		});
	}

	const noTagsTitle = striptags(title);

	const contextData = {
		segments,
		articleId,
		uri,
		categories,
		enableVideoPlaylist,
		social: {
			analyticsLinkLocation,
			title: noTagsTitle,
			urlEncoded: encodeURIComponent(
				`http://www.eonline.com${edition !== 'us' ? `/${edition}` : ''}/news/${articleId}` +
					(articleTitle ? `/${articleTitle}` : '')
			)
		},
		hasEcommerce,
		hasContextualWidget,
		renderContextualWidget
	};

	const articleDetailTaboolaInitData = [
		{
			placementName: belowArticleThumbPlacementName,
			mode: 'thumbnails-feed-g',
			instancesToGenerate: 1
		}
	];

	const adKeywords = branding?.ads?.adKeywords;
	const disclosureText = branding?.disclosureText;

	return (
		<ArticleDetailContext.Provider value={contextData}>
			<main className="article-detail">
				<HrefLang uri={pageMetaData?.defaultUrl} hrefLang={pageMetaData?.hreflang} />
				<MpsDataConfig
					contentId={articleId}
					categories={categories}
					pageAdKeyword={adKeywords}
				/>
				<ArticleHead
					article={article}
					articleId={articleId}
					hasShopCategory={hasShopCategory}
				/>
				<DataLayer pageData={createAnalyticsData(article)} />
				<NielsenStaticDCR assetId={`article-${articleId}`} siteSection="article-detail" />
				<header>
					<div className="columns is-desktop">
						<div className="column is-10-desktop is-offset-1-desktop">
							{snipe && (
								<SnipeBanner snipe={snipe} firstPublishDate={firstPublishDate} />
							)}
							<h1 className="article-detail__title">{parseHtml(title)}</h1>
							<h2 data-hook="adp-subhead" className="article-detail__subheader">
								{(adKeywords || disclosureText) && <SponsoredBy {...branding} />}
								{!show && parseHtml(subhead)}
							</h2>

							<ArticleMeta
								authors={authors}
								publishedByUsers={publishedByUsers}
								date={publishDate}
								translatedBy={translators}
							/>
						</div>
					</div>
				</header>
				<div className="columns is-desktop">
					<div className="column is-7-desktop is-offset-1-desktop article-detail__main-content">
						{show && <ShowBranding show={show} />}
						{showWellsFargoAd && <WellsFargoAd />}
						<Segments
							segments={segments}
							hasVerticalGallery={hasVerticalGallery}
							autoPlayTopVideo={article?.autoPlayTopVideo}
						/>
					</div>
					<div className="column is-4-desktop is-hidden-touch article-detail__right-rail">
						<div className="article-detail__right-rail--topad">
							<MpsAd slot="boxflex" isDesktopOnly={true} isHiddenOnError={true} />
						</div>
						{hasContextualWidget && renderContextualWidget ? (
							<div data-usc-contextual-recommends-widget></div>
						) : TABOOLA_TRENDING_STORIES_PARAM ? (
							breakpointWidth === 'desktop' && (
								<Taboola
									pageName="article"
									initData={[
										{
											placementName:
												'Desktop Right Rail Article - Content Recs',
											mode: 'organic-thumbnails-trending',
											instancesToGenerate: 1
										}
									]}
									generateContainers={true}
									isOnLoad={true}
								/>
							)
						) : (
							<WidgetTrending location="article-right-rail" />
						)}
						{blockTaboola && <LatestNews />}
						<div id="right-rail-article-thumbnails"></div>
					</div>
				</div>
				{!blockTaboola && (
					<Taboola pageName="article" initData={articleDetailTaboolaInitData} />
				)}
				<div id={belowArticleThumbContainerName}></div>
				{uppercaseEdition === 'US' && (
					<MpsAd id="kargo" slot="kargo" className="mps-kargo" />
				)}
			</main>
		</ArticleDetailContext.Provider>
	);
};

ArticleDetail.displayName = 'ArticleDetail';

export default ArticleDetail;
