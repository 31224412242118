import React from 'react';
import './WellsFargoAd.scss';

const desktopImg =
	'https://akns-images.eonline.com/eol_images/Entire_Site/202372/E_Desktop_banner.png';
const mobileImg =
	'https://akns-images.eonline.com/eol_images/Entire_Site/202372/E_Mobile_banner.png';

/**
 * @function WellsFargoAd
 * @returns {React.ReactElement}
 */
const WellsFargoAd = () => (
	<>
		<div
			className="sponsor-asset wells-fargo-ad"
			data-nbcu-checkout-sponsor
			data-analytics-custom-attributes="sponsorName:Wells Fargo"
		>
			<a
				href="https://creditcards.wellsfargo.com/cards/active-cash-view-details/?FPID=0126D7I6F40000&product_code=CC&subproduct_code=AC&sub_channel=DIS&vendor_code=NB&placement_id=DIS_NB_CCD_AC_SHOP_ATL_E&creative_id=NBCUCOMM_E_AC2PER_LM"
				// eslint-disable-next-line react/jsx-no-target-blank
				target="_blank"
			>
				<img
					alt="Wells Fargo Active Cash Credit Card"
					src={mobileImg}
					className="wells-fargo-ad--mobile"
					width="1125"
					height="255"
				/>
				<img
					alt="Wells Fargo Active Cash Credit Card"
					src={desktopImg}
					className="wells-fargo-ad--desktop"
					width="1893"
					height="191"
				/>
			</a>
		</div>
		{/* Kantar tracking pixel */}
		<img
			width="0"
			height="0"
			alt=""
			src="https://secure.insightexpressai.com/adServer/adServerESI.aspx?script=false&bannerID=11514517&rnd=[INSERT_CACHEBUSTER_HERE]&redir=https://secure.insightexpressai.com/adserver/1pixel.gif"
		/>
		{/* Video Amp tracking pixels */}
		<img
			width="0"
			height="0"
			alt=""
			src="https://b.videoamp.com/d2/ca3b73ca-4949-4000-8a9d-4d07e9cb6c9d/7284/impression?bwb=1548&vpxid=7284"
		/>
		<img
			width="0"
			height="0"
			alt=""
			src="https://b.videoamp.com/d2/cd21092f-2c8b-425b-8128-43bf9bea09db/7285/impression?bwb=35&vpxid=7285&dnt=false&us_privacy={{US_PRIVACY_STRING}}"
		/>
	</>
);

WellsFargoAd.displayName = 'WellsFargoAd';

export default WellsFargoAd;
