import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { object, string, bool } from 'prop-types';
import striptags from 'striptags';
import parseHtml from '@/utils/parseHtml';
import MetaTags from '@/components/meta-tags/MetaTags';
import ArticleStructuredData from './ArticleStructuredData';
import { usePageContext } from '@/components/layout/page/PageContext';
import { formatCategories } from '@/components/meta-tags/helpers';

/**
 * @function checkIsUnoptimizedArticle
 * @description checks for dated article ids
 * @param {String|Number} articleId
 * @returns {Boolean}
 */
const checkIsUnoptimizedArticle = (articleId) => parseInt(articleId) <= 250000;

/**
 * @function getAmpUri
 * @description assembles international amp url
 * @param {String} edition
 * @param {String} uri
 * @returns {String} formatted uri
 */
const getAmpUri = (edition, uri) => {
	if (!edition || !uri) return '';

	const domain = `https://www.eonline.com`;

	// for the us edition we only need to add amp to the uri
	if (edition === 'us') return `${domain}/amp${uri}`;

	// remove edition from uri and combine: edition/amp/uriWithoutEdition
	return `${domain}/${edition}/amp${uri.replace(/^\/[a-z]{2}\//i, '/')}`;
};

/**
 * @function ArticleHead
 * @description renders adp specific head elements and meta tags
 * @param {Object} props
 * @param {Object} props.article article data
 * @param {String} props.articleId
 * @param {Boolean} props.hasShopCategory
 * @returns {React.ReactElement}
 */
const ArticleHead = ({ article, articleId, hasShopCategory }) => {
	const { edition } = usePageContext();
	if (!articleId || !article) return null;
	const {
		categories,
		lastModDate,
		pageMetaData,
		publishDate,
		subhead,
		thumbnail,
		title,
		shortTitle,
		seoHeadline,
		uri
	} = article;
	const canonicalUrl = pageMetaData?.canonicalUrl;
	const metaTitle = seoHeadline || shortTitle || title;
	const isUnoptimizedArticle = checkIsUnoptimizedArticle(articleId);
	const metaTagData = {
		id: articleId,
		title,
		publishDate,
		categories,
		description: subhead || 'News article on E!',
		thumbnailUri: thumbnail?.uri,
		options: { twitter: true, sailthru: true, ogTags: true },
		customPropTags: {
			'article:tag': formatCategories(categories),
			'article:published_time': publishDate,
			'article:modified_time': lastModDate,
			'article:content_tier': 'free',
			'article:opinion': 'false'
		},
		customNameTags: {
			robots: isUnoptimizedArticle ? 'noindex' : 'max-image-preview:large'
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>{parseHtml(striptags(metaTitle))}</title>
				{/* removing the below for NDPPS-1175 Deprecate Google AMP versions for E! Articles */}
				{/* <link rel="amphtml" href={getAmpUri(edition, uri)}></link> */}
				{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
				{hasShopCategory && (
					<script
						type="text/javascript"
						src="https://s.skimresources.com/js/94261X1548625.skimlinks.js"
					></script>
				)}
			</Helmet>
			<MetaTags {...metaTagData} />
			<ArticleStructuredData article={article} />
		</Fragment>
	);
};

ArticleHead.propTypes = {
	article: object.isRequired,
	articleId: string.isRequired,
	hasShopCategory: bool
};

ArticleHead.displayName = 'ArticleHead';

export default ArticleHead;
