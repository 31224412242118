import React from 'react';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { usePageContext, useFeatureToggleContext } from '@/components/layout/page/PageContext';
import AmpSegmentAd from '@/components/pages/amp/ads/AmpSegmentAd';
import MpsAd from '@/components/mps/MpsAd';
import Taboola from '@/components/taboola/Taboola';
import './SegmentAds.scss';
import { bool, number } from 'prop-types';
import getTaboolaPlacement from './getTaboolaPlacement';

/**
 * @function SegmentAds
 * @description contains logic to either server amp or non amp ad
 * @param {Object} props
 * @param {Boolean} props.hasVerticalGallery If its an ADP that has a vertical gallery
 * @param {Boolean} [props.isInVerticalGallery] whether this segment block is a vertical gallery (used to differentiate the placement names)
 * @param {Number} props.count
 * @returns {React.ReactElement}
 */
const SegmentAds = ({ hasVerticalGallery, isInVerticalGallery, count }) => {
	const { isAmp, uppercaseEdition } = usePageContext();
	const { MPS_ADS_PARAM } = useParamToggleContext();
	const { MPS_ADS } = useFeatureToggleContext();

	const editionList = ['CA', 'MX', 'AR', 'CO', 'VE', 'BR'];

	// adjust count for vertical gallery; Taboola ad should be in the first position
	const adjustedCount = isInVerticalGallery ? count + 1 : count;

	// The inline ad should show if its the first ad or always show if its in the edition list
	const isShowInline =
		MPS_ADS_PARAM || MPS_ADS
			? adjustedCount !== 2 || editionList.includes(uppercaseEdition)
			: (count === 1 && !isInVerticalGallery) || editionList.includes(uppercaseEdition);

	const taboolaCount = MPS_ADS_PARAM || MPS_ADS ? 1 : isInVerticalGallery ? count : count - 1;

	const { placementName, mode } = getTaboolaPlacement({
		taboolaCount,
		isInVerticalGallery,
		hasVerticalGallery,
		isAmp
	});

	if (isAmp && placementName) {
		return (
			<AmpSegmentAd
				taboolaPlacementName={placementName}
				mode={mode}
				showTaboolaAd={!isShowInline}
			/>
		);
	} else if (isShowInline) {
		return (
			<div data-hook="adp-segment-ad" className="article-detail__segment-ad">
				<MpsAd slot="inlinearticle" repeatable={true} centered isHiddenOnError={true} />
			</div>
		);
	}

	return placementName ? (
		<Taboola
			pageName="article"
			initData={[
				{
					placementName,
					mode,
					instancesToGenerate: 1
				}
			]}
			generateContainers
		/>
	) : null;
};

SegmentAds.defaultProps = {
	count: 0,
	hasVerticalGallery: false,
	isInVerticalGallery: false
};

SegmentAds.propTypes = {
	hasVerticalGallery: bool.isRequired,
	isInVerticalGallery: bool,
	count: number.isRequired
};

SegmentAds.displayName = 'SegmentAds';

export default SegmentAds;
