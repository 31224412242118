import React, { useContext } from 'react';
import parseHtml from '@/utils/parseHtml';
import { bool, string } from 'prop-types';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import SocialShare from '@/components/social-share/SocialShare';

/**
 * @function TextOnly
 * @param {Boolean} [props.includeSocialShare]
 * @param {String} props.text - text from cms, may have html formatting
 * @returns {React.ReactElement}
 */
const TextOnly = ({ includeSocialShare, text }) => {
	const { social, hasEcommerce } = useContext(ArticleDetailContext);
	if (!text) return null;

	// remove 'noreferrer' from links in articles that have an ecommerce block
	const filteredText = hasEcommerce
		? text.replace(/rel="noopener noreferrer"/g, 'rel="noopener"')
		: text;

	return (
		<div className="article-detail__text-only" data-testid="segment-text-only">
			{includeSocialShare && <SocialShare {...social} />}
			{parseHtml(filteredText)}
		</div>
	);
};

TextOnly.propTypes = {
	includeSocialShare: bool,
	text: string.isRequired
};

TextOnly.displayName = 'TextOnly';

export default TextOnly;
