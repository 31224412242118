import React from 'react';
import { PageConsumer } from '@/components/layout/page/PageContext';
import { string } from 'prop-types';
import Translate from '@/components/i18n/Translate';

/**
 * @function ArticleMetaTranslatedBy
 * @description - displays translated by name if conditions are met
 * @param {Object} props
 * @param {String} [props.translatedBy] - persona that translated the article
 * @returns {React.ReactElement}
 */
const ArticleMetaTranslatedBy = ({ translatedBy }) => {
	if (!translatedBy) return null;
	return (
		<PageConsumer>
			{({ edition }) => {
				const showEditions = {
					fr: true,
					de: true,
					ar: true,
					co: true,
					mx: true,
					ve: true,
					br: true
				};
				const isShow = translatedBy && showEditions[edition];
				return isShow ? (
					<span className="article-detail__meta-split">
						<span className="is-hidden-touch">{' | '}</span>
						<Translate word="TRANSLATED_BY" />{' '}
						<span
							data-hook="adp-meta-translatedby"
							className="article-detail__meta__tsby"
						>
							{translatedBy}
						</span>
						<span className="is-hidden-touch">{' | '}</span>
					</span>
				) : null;
			}}
		</PageConsumer>
	);
};

ArticleMetaTranslatedBy.propTypes = {
	translatedBy: string
};

ArticleMetaTranslatedBy.displayName = 'ArticleMetaTranslatedBy';

export default ArticleMetaTranslatedBy;
