import React from 'react';
import format from 'date-fns/format';
import dateFnsDe from 'date-fns/locale/de';
import dateFnsFr from 'date-fns/locale/fr';
import dateFnsEs from 'date-fns/locale/es';
import dateFnsPt from 'date-fns/locale/pt';
import { PageConsumer } from '@/components/layout/page/PageContext';
import { string } from 'prop-types';

const locale = {
	EN: null,
	ES: dateFnsEs,
	FR: dateFnsFr,
	DE: dateFnsDe,
	PT: dateFnsPt
};

/**
 * @function arrangeTokens
 * @description arranges (month day year time) tokens by edition preference
 * @param {String} edition
 * @returns {String} - arranged date format tokens
 */
const arrangeTokens = (edition) => {
	const monthDayYear24hr = {
		fr: true,
		de: true
	};

	const dayMonthYear24hr = {
		ar: true,
		br: true
	};

	const dayMonthYearampm = {
		mx: true,
		co: true,
		ve: true,
		ap: true
	};

	if (monthDayYear24hr[edition]) return 'MMM dd, yyyy HH:mm';
	if (dayMonthYear24hr[edition]) return 'dd MMM, yyyy HH:mm';
	if (dayMonthYearampm[edition]) return 'dd MMM, yyyy h:mm a';
	/* default */
	return 'MMM dd, yyyy h:mm a';
};

/**
 * @function MetaDate
 * @description formats and displays date by edition
 * @param {String} date - publishDate
 * @param {String} className - class name
 * @returns {React.ReactElement}
 */
const MetaDate = ({ date, className }) => {
	return (
		<PageConsumer>
			{({ lang, edition }) => {
				return (
					<span className={className}>
						{format(new Date(date), arrangeTokens(edition), { locale: locale[lang] })}
					</span>
				);
			}}
		</PageConsumer>
	);
};

MetaDate.propTypes = {
	date: string,
	className: string
};

MetaDate.displayName = 'MetaDate';

export default MetaDate;
