import React from 'react';
import { string } from 'prop-types';

const CheckoutCollection = ({ checkoutCollectionKey }) => (
	<div className="article-detail__checkout-collection">
		<div className="article-detail__embeddable-product-widget">
			<div
				data-usc-embeddable-products-widget
				data-category-key={checkoutCollectionKey}
			></div>
		</div>
	</div>
);

CheckoutCollection.propTypes = {
	checkoutCollectionKey: string
};

CheckoutCollection.displayName = 'CheckoutCollection';

export default CheckoutCollection;
