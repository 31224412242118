import React from 'react';
import TextOnly from './TextOnly';
import VideoOnly from './VideoOnly';
import { bool, string, object, number } from 'prop-types';

/**
 * @function VideoText
 * @description displays both Video and Text
 * @param {Object} props
 * @param {Boolean} [props.includeSocialShare]
 * @param {Number} props.segmentIndex - location of the segment in the article
 * @param {String} props.text - text from cms, may have html formatting
 * @param {Object} [props.video] - video object with video and thubnail data; can be null if video is expired
 * @param {Boolean} [props.autoPlayTopVideo]
 * @returns {React.ReactElement}
 */
const VideoText = ({ includeSocialShare, text, video, segmentIndex, autoPlayTopVideo }) => {
	if (!text || segmentIndex === undefined) return null;
	return (
		<div className="article-detail__video-text">
			{video && (
				<VideoOnly
					autoPlayTopVideo={autoPlayTopVideo}
					includeSocialShare={includeSocialShare}
					video={video}
					segmentIndex={segmentIndex}
				/>
			)}
			<TextOnly text={text} />
		</div>
	);
};

VideoText.propTypes = {
	includeSocialShare: bool,
	autoPlayTopVideo: bool,
	segmentIndex: number.isRequired,
	text: string.isRequired,
	video: object
};

VideoText.displayName = 'VideoText';

export default VideoText;
