import React, { Fragment, useContext } from 'react';
import Thumb from '@/components/thumb/Thumb';
import { bool, object, number } from 'prop-types';
import SocialShare from '@/components/social-share/SocialShare';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';

/**
 * @function PhotoOnly
 * @param {Object} props.image - image object from db
 * @param {Boolean} [props.includeSocialShare]
 * @param {Number} [props.segmentIndex]
 * @param {Boolean} [props.lazyLoad] should the image lazy load
 * @return {React.ReactElement}
 */
const PhotoOnly = ({ image, includeSocialShare, segmentIndex, lazyLoad }) => {
	const { social } = useContext(ArticleDetailContext);
	if (!image) return null;

	/**
	 * to help with content load faster we decide to not lazyload the first image on adp
	 * in segments PHOTO_ONLY, PHOTO_TEXT, and VERTICAL_GALLERY
	 * PHOTO_TEXT and VERTICAL_GALLERY will be passing in the boolean lazyLoad and segmentIndex should be undefined
	 * PHOTO_ONLY will be checking segmentIndex
	 */
	const shouldNotLazyLoad = !!(segmentIndex === 0 || !lazyLoad);

	// setting default sourceWith to 800
	// Thumb requires a width
	const { title, id, uri, sourceHeight, sourceWidth = '800', agency } = image;
	// passing height & width to create a custom padding-top: %;
	// custom aspect ratios need height and width props in thumbnail object
	const thumbnail = {
		id,
		uri,
		height: sourceHeight,
		width: sourceWidth,
		title
	};
	return (
		<Fragment>
			<div className="article-detail__image-wrap">
				<Thumb thumbnail={thumbnail} withLink={false} lazyLoad={!shouldNotLazyLoad} />
				{agency && (
					<span data-hook="adp-photo-agency" className="article-detail__image-agency">
						{agency}
					</span>
				)}
				{includeSocialShare && <SocialShare {...social} />}
			</div>
		</Fragment>
	);
};

PhotoOnly.defaultProps = {
	lazyLoad: true
};

PhotoOnly.propTypes = {
	image: object.isRequired,
	includeSocialShare: bool,
	segmentIndex: number,
	lazyLoad: bool
};

PhotoOnly.displayName = 'PhotoOnly';

export default PhotoOnly;
