import React from 'react';
import { array, string } from 'prop-types';
import Translate from '@/components/i18n/Translate';
import MetaDate from '@/components/date/MetaDate';
import ArticleMetaAuthors from './ArticleMetaAuthors';
import ArticleMetaTranslatedBy from './ArticleMetaTranslatedBy';
import ArticleCategories from './ArticleCategories';
import './ArticleMeta.scss';

/**
 * @function ArticleMeta
 * @description displays meta info of article
 * @param {Array} authors - authors of article from sage
 * @param {Array} publishedByUsers - authors of article from neon
 * @param {Array} translatedBy - who translated article
 * @param {Array} date - publishDate of article
 * @returns {React.ReactElement}
 */
const ArticleMeta = ({ authors, publishedByUsers, translatedBy, date }) => {
	if (!authors || !date) return null;
	return (
		<div className="article-detail__meta">
			<span className="article-detail__meta-split">
				<span className="article-detail__by">
					<Translate word="BY" />
				</span>
				<ArticleMetaAuthors authors={authors} publishedByUsers={publishedByUsers} />
			</span>
			<span className="article-detail__meta-split">
				{translatedBy && <ArticleMetaTranslatedBy translatedBy={translatedBy} />}
				<MetaDate date={date} className="article-detail__meta__date" />
				<ArticleCategories />
			</span>
		</div>
	);
};

ArticleMeta.propTypes = {
	authors: array,
	publishedByUsers: array,
	date: string.isRequired,
	translatedBy: string
};

ArticleMeta.displayName = 'ArticleMeta';

export default ArticleMeta;
