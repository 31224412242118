import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import DockableVideo from '@/components/video/dockable-video/DockableVideo';
import { GammaPlayer } from '@nbcnews/gamma';
import { usePageContext } from '@/components/layout/page/PageContext';
import { bool, object, number } from 'prop-types';
import SocialShare from '@/components/social-share/SocialShare';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import VIDEO_FEED_BY_ID from '@/graphql/queries/VIDEO_FEED_BY_ID';
import { getCookie } from '@/utils/cookies';
import Translate from '@/components/i18n/Translate';
import { getFreewheelUserId, generateCsid } from '@/components/video/videoHelpers';
import './VideoOnly.scss';
import '@/components/video/video-player/GammaPlayer.scss';

const feedId = {
	US: 'rQtJxSxl_9l7',
	CA: 'CA_TOP_NEWS_VIDEO',
	AU: 'AU_Video_Landing_Left_Footer',
	UK: 'UK_Video_Landing_Left_Footer',
	DE: 'DE_Video_Landing_Top_Carousel',
	FR: 'FR_Video_Nav_Front_Door',
	AP: 'ap_vdp_top_stories',
	BR: 'br_top_news_vdp',
	MX: 'mx_top_news_vdp',
	AR: 'ar_top_news_vdp',
	CO: 'co_top_news_vdp',
	VE: 've_top_news_vdp'
};

/**
 * @const editionWithSpaceBeforeColon
 * @description case insensitive regex targeting editions where space should be added before a colon
 */
const editionWithSpaceBeforeColon = /^(FR)$/i;

/**
 * @function VideoOnly
 * @description takes video info and displays a video object
 * @param {Object} props
 * @param {Boolean} [props.includeSocialShare]
 * @param {Number} [props.segmentIndex] - location of the segment in the article
 * @param {Object} [props.video] - video info, can be null if video is expired
 * @params {Boolean} [props.autoPlayTopVideo]
 * @return {React.ReactElement}
 */
const VideoOnly = ({ includeSocialShare, segmentIndex, video, autoPlayTopVideo }) => {
	const [currentVideo, setCurrentVideo] = useState(video);
	const { social, enableVideoPlaylist } = useContext(ArticleDetailContext);
	const { edition, isAmp, uppercaseEdition } = usePageContext();

	// note we are hardcoding autostart to true following neon articles launch
	const autoPlay = useRef(autoPlayTopVideo);
	const nextVideoIndex = useRef(0);
	const [csidValue, setCsidValue] = useState('');
	let fwReferer;
	let uid;
	const usprivacy = getCookie('usprivacy');

	const feedQuery = useQuery(VIDEO_FEED_BY_ID, {
		variables: { id: feedId[uppercaseEdition], edition: uppercaseEdition, limit: 6, skip: 0 }
		// prior to NEON articles launch we were including the below in the query
		// skip: !enableVideoPlaylist
	});

	const rawFeed = feedQuery?.data?.videoFeed?.videos;
	const cleanFeed = rawFeed && rawFeed.filter(({ id }) => id !== video?.id).slice(0, 5);

	// PDK video event listener callbacks
	const videoPlayerCallbacks = {
		// video end event - fire when a whole video release, and not just an ad, ends
		onReleaseStart: (e) => {
			autoPlay.current = true;
		},
		onReleaseEnd: (e) => {
			const nextVideo = cleanFeed?.[nextVideoIndex.current];
			if (nextVideo) {
				setCurrentVideo(nextVideo);
				nextVideoIndex.current = nextVideoIndex.current + 1;
			}
		}
	};

	if (typeof window !== 'undefined') {
		fwReferer = window.location.hostname;
		uid = getFreewheelUserId();
	}

	const onPlayerReady = (player) => {
		const appMeasurement = new window.AppMeasurement('comcastegeonlineglobaldev');
		// eslint-disable-next-line no-undef
		appMeasurement.visitor = visitor;
		appMeasurement.trackingServer = 'swa.eonline.com';

		const mediaConfig = new window.VodADB.MediaConfig();
		mediaConfig.trackingServer = 'swa.eonline.com/va';
		mediaConfig.playerName = 'E! Gamma Player';
		mediaConfig.channel = 'on-domain';
		mediaConfig.appVersion = '1';
		mediaConfig.debugLogging = false;
		mediaConfig.ssl = true;

		window.VodADB.Media.configure(mediaConfig, appMeasurement);

		const tracker = window.VodADB.Media.getInstance();

		const adBreakObject = window.VodADB.Media.createAdBreakObject('preroll', 1, 0);

		const adMetaDataObject = {
			type: 'preroll',
			assetid: null
		};

		let myInterval;
		let adInterval;
		let adPosition;
		let adFlag;
		let adTimerFlag;

		const contentMetadataObject = {
			type: 'content',
			assetid: currentVideo.id || '',
			program: 'E! News Now',
			title: currentVideo.title || '',
			length: currentVideo.duration || '',
			isfullepisode: 'n',
			adloadtype: '2',
			airdate: currentVideo.publishDate.split('.')[0] || ''
		};

		player.on('complete', (e) => {
			tracker.trackComplete();
			const nextVideo = cleanFeed?.[nextVideoIndex.current];
			if (nextVideo) {
				setCurrentVideo(nextVideo);
				nextVideoIndex.current = nextVideoIndex.current + 1;
			}
			window.nSdkVideoInstance.ggPM('end', Math.round(player.getCurrentTime()));
			clearInterval(myInterval);
		});
		player.on('firstFrame', (e) => {
			adTimerFlag = false;
			if (!adFlag) {
				const mediaObject = window.VodADB.Media.createMediaObject(
					currentVideo.title || '',
					currentVideo.id || '',
					currentVideo.duration || 0,
					window.VodADB.Media.StreamType.VOD || '',
					window.VodADB.Media.MediaType.Video || ''
				);
				tracker.trackSessionStart(mediaObject);
			}
			tracker.trackPlay();
			adFlag = false;
			if (nextVideoIndex.current > 0) {
				contentMetadataObject.assetid = cleanFeed[nextVideoIndex.current - 1].id || '';
				contentMetadataObject.title = cleanFeed[nextVideoIndex.current - 1].title || '';
				contentMetadataObject.length = cleanFeed[nextVideoIndex.current - 1].duration || '';
				contentMetadataObject.publishDate =
					cleanFeed[nextVideoIndex.current - 1].publishDate || '';
			}
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			myInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM(
					'setPlayheadPosition',
					Math.round(player.getPosition())
				);
				tracker.updatePlayhead(Math.round(player.getPosition()));
			}, 1000);
			autoPlay.current = true;
		});
		player.on('ready', (e) => {
			// eslint-disable-next-line no-undef
			ns_.StreamingAnalytics.JWPlayer(player, {
				publisherId: '603508',
				// eslint-disable-next-line no-useless-escape
				labelmapping: `ns_st_st=\"E! News-vod\", ns_st_pu=\"E!\", ns_st_ge=\"news\", c3=\"EOnline\", ns_st_ci=\"${currentVideo.id}\"`
			});
		});
		player.on('play', (e) => {
			if (e.oldstate === 'paused') {
				tracker.trackPlay();
				window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
				myInterval = setInterval(function () {
					window.nSdkVideoInstance.ggPM(
						'setPlayheadPosition',
						Math.round(player.getPosition())
					);
					tracker.updatePlayhead(Math.round(player.getPosition()));
				}, 1000);
			}
		});
		player.on('pause', (e) => {
			tracker.trackPause();
			clearInterval(myInterval);
		});
		player.on('adBreakStart', (e) => {
			const mediaObject = window.VodADB.Media.createMediaObject(
				currentVideo.title || '',
				currentVideo.id || '',
				currentVideo.duration || 0,
				window.VodADB.Media.StreamType.VOD || '',
				window.VodADB.Media.MediaType.Video || ''
			);
			tracker.trackSessionStart(mediaObject);
			adFlag = true;
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakStart, adBreakObject);
		});
		player.on('adComplete', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdComplete);
			window.nSdkVideoInstance.ggPM('stop', Math.round(adPosition));
			clearInterval(adInterval);
		});
		player.on('adBreakEnd', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakComplete);
		});
		player.on('adSkipped', (e) => {
			clearInterval(adInterval);
		});
		player.on('seek', (e) => {
			window.nSdkVideoInstance.ggPM('stop', Math.round(player.getCurrentTime()));
		});
		player.on('adPause', (e) => {
			clearInterval(adInterval);
		});
		player.on('adTime', (e) => {
			adPosition = e.position;
			if (adTimerFlag !== true) {
				tracker.updatePlayhead(Math.round(adPosition));
				const adMetadata = {};
				adMetadata[window.VodADB.Media.AdMetadataKeys.Advertiser] = e.client;
				const adObject = window.VodADB.Media.createAdObject('preroll', e.id, 1, e.duration);
				tracker.trackEvent(window.VodADB.Media.Event.AdStart, adObject, adMetadata);
				adTimerFlag = true;
			}
		});
		player.on('adPlay', (e) => {
			contentMetadataObject.assetid = currentVideo.id || '';
			contentMetadataObject.title = currentVideo.title || '';
			contentMetadataObject.length = currentVideo.duration || '';
			contentMetadataObject.airdate = currentVideo.publishDate.split('.')[0] || '';
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			adMetaDataObject.assetid = e.id;
			window.nSdkVideoInstance.ggPM('loadMetadata', adMetaDataObject);
			adInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM('setPlayheadPosition', Math.round(adPosition));
				tracker.updatePlayhead(Math.round(adPosition));
			}, 1000);
		});
		player.on('error', (e) => {
			window.nSdkVideoInstance.ggPM('end', Math.round(player.getCurrentTime()));
			window.nSdkVideoInstance.ggPM('stop', Math.round(player.getCurrentTime()));
			if (e.code > 232399 && e.code <= 232600) {
				convertedVideos.forEach((el) => {
					el.file = el.file.split('formats')[0] + 'formats=MPEG4';
					el.type = 'mp4';
				});
				player.load(convertedVideos);
				player.play();
			}
		});
	};

	useEffect(() => {
		setCsidValue(generateCsid());
	}, []);

	const hlsParams =
		'/?mbr=true&format=redirect&manifest=m3u&format=redirect&Tracking=true&Embedded=true&formats=M3U';
	const convertedVideos = cleanFeed
		? cleanFeed.map((video) => {
				return {
					file: video.videoUri + hlsParams,
					title: video.title,
					duration: video?.duration || 0,
					type: 'hls',
					id: video?.id || '',
					fwassetid: `eonline-${video?.id}`,
					image: video?.hdThumbnail?.uri,
					tracks: [
						{
							kind: 'captions',
							file: video?.webVttUri,
							label: 'English'
						}
					]
				};
		  })
		: [];
	if (currentVideo)
		convertedVideos.unshift({
			file: currentVideo.videoUri + hlsParams,
			title: currentVideo.title,
			duration: currentVideo.duration || 0,
			type: 'hls',
			id: currentVideo.id,
			fwassetid: `eonline-${currentVideo.id}`,
			image: currentVideo?.hdThumbnail?.uri,
			tracks: [
				{
					kind: 'captions',
					file: currentVideo?.webVttUri,
					label: 'English',
					name: ''
				}
			]
		});

	return (
		<Fragment>
			{currentVideo && (
				<Fragment>
					{!isAmp ? (
						<DockableVideo
							title={currentVideo.title}
							playerId={`video-adp-${currentVideo.publicId}-${segmentIndex}`}
							playerCallbacks={videoPlayerCallbacks}
						>
							<GammaPlayer
								className="gamma-only"
								jwPlayerLibraryUrl="https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.28.0/jwplayer.js"
								jwPlayerLibraryKey="+9o3ihbMIU8/ixFry35xlHnkQ9tikKg9TU0io1QbWXfpeR0q"
								floating={{
									dismissible: true
								}}
								skin={{
									name: 'myskin'
								}}
								playlist={convertedVideos}
								related={{
									autoplaytimer: 0
								}}
								width="100%"
								height="100%"
								mute={true}
								jwPlayerRef={onPlayerReady}
								autostart={true}
								advertising={{
									client: 'freewheel',
									freewheel: {
										networkid: 169843,
										serverid: 'https://29773.v.fwmrm.net/ad/g/1',
										profileid: '169843:nbcu_web_jwp_cs_moat_https',
										sectionid: csidValue,
										adManagerUrl: `https://mssl.fwmrm.net/libs/adm/6.51.0/AdManager.js`,
										afid: '127497404',
										sfid: '586754',
										vcid: uid,
										capabilities: {
											flag: {
												dtrd: 'on',
												amcb: 'on',
												sbid: 'on'
											}
										},
										custom: {
											_fw_vcid2: uid,
											_fw_h_referer: fwReferer,
											_fw_player_width: 890,
											_fw_player_height: 498,
											_fw_us_privacy: usprivacy
										}
									},
									adscheduleid: '12345',
									schedule: {
										adbreak: {
											offset: 'pre',
											tag: 'placeholder_preroll'
										}
									},
									vpaidcontrols: true,
									skipoffset: 3
								}}
							/>
						</DockableVideo>
					) : (
						<GammaPlayer
							className="gamma-only"
							jwPlayerLibraryUrl="https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.28.0/jwplayer.js"
							jwPlayerLibraryKey="+9o3ihbMIU8/ixFry35xlHnkQ9tikKg9TU0io1QbWXfpeR0q"
							floating={{
								dismissible: true
							}}
							skin={{
								name: 'myskin'
							}}
							playlist={convertedVideos}
							related={{
								autoplaytimer: 0
							}}
							width="100%"
							height="100%"
							jwPlayerRef={onPlayerReady}
							advertising={{
								client: 'freewheel',
								freewheel: {
									networkid: 169843,
									serverid: 'https://29773.v.fwmrm.net/ad/g/1',
									profileid: '169843:nbcu_web_jwp_cs_moat_https',
									sectionid: csidValue,
									adManagerUrl: `https://mssl.fwmrm.net/libs/adm/6.51.0/AdManager.js`,
									afid: '127497404',
									sfid: '586754',
									vcid: uid || '',
									capabilities: {
										flag: {
											dtrd: 'on',
											amcb: 'on',
											sbid: 'on'
										}
									},
									custom: {
										_fw_vcid2: uid,
										_fw_h_referer: fwReferer,
										_fw_player_width: 890,
										_fw_player_height: 498,
										_fw_us_privacy: usprivacy
									}
								},
								adscheduleid: '12345',
								schedule: {
									adbreak: {
										offset: 'pre',
										tag: 'placeholder_preroll'
									}
								},
								vpaidcontrols: true,
								skipoffset: 3
							}}
						/>
					)}
					<div className="text--xxs margin-bottom--md" data-hook="video-only-text">
						<span className="has-text-weight-extrabold">
							<Translate word="RELATED" />
							{editionWithSpaceBeforeColon.test(edition) ? ' :' : ':'}
						</span>{' '}
						{currentVideo.title}
					</div>
				</Fragment>
			)}
			{includeSocialShare && <SocialShare {...social} />}
		</Fragment>
	);
};

VideoOnly.propTypes = {
	includeSocialShare: bool,
	autoPlayTopVideo: bool,
	segmentIndex: number,
	video: object
};

VideoOnly.displayName = 'VideoOnly';

export default VideoOnly;
