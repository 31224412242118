import React, { Fragment } from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import TextOnly from './TextOnly';
import HtmlOnly from './HtmlOnly';
import { string } from 'prop-types';

/**
 * @function HtmlText
 * @param {String} html
 * @param {String} [text]
 * @returns {React.ReactElement}
 */
const HtmlText = ({ html, text }) => {
	const { isAmp } = usePageContext();
	if (!html || isAmp) return null;
	return (
		<Fragment>
			<HtmlOnly html={html} />
			<TextOnly text={text} />
		</Fragment>
	);
};

HtmlText.propTypes = {
	html: string.isRequired,
	text: string
};

HtmlText.displayName = 'HtmlText';

export default HtmlText;
