import React from 'react';
import AmpList from './AmpList';
import AmpAd from './AmpAd';
import AmpTaboola from '../ads/AmpTaboola';
import { string, bool } from 'prop-types';

/**
 * @function AmpSegmentAd
 * @description renders amp-ad between amp article detail segments
 * @param {Object} props
 * @param {String} [props.taboolaPlacementName]
 * @param {Boolean} [props.showTaboolaAd]
 * @param {String} [props.mode]
 * @return {React.ReactElement}
 */
const AmpSegmentAd = ({ taboolaPlacementName, mode, showTaboolaAd }) => {
	return (
		<div
			className={`amp__segment-ad ${
				showTaboolaAd ? 'amp__segment-ad--responsive' : 'amp__segment-ad--fixed'
			}`}
		>
			{showTaboolaAd ? (
				<AmpTaboola placementName={taboolaPlacementName} mode={mode} />
			) : (
				<AmpList>
					<AmpAd width="300" height="250" slotname="ampbox" />
				</AmpList>
			)}
		</div>
	);
};

AmpSegmentAd.propTypes = {
	taboolaPlacementName: string,
	showTaboolaAd: bool,
	mode: string
};

AmpSegmentAd.displayName = 'AmpSegmentAd';

export default AmpSegmentAd;
