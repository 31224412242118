import React from 'react';
import parseHtml from '@/utils/parseHtml';
import './CategoryButton.scss';
import { string } from 'prop-types';
import createAnalyticsString from '@/utils/createAnalyticsString';

/**
 * @function CategoryButton
 * @description small category button usually on ADP and VDP
 * @param {Object} props
 * @param {String} props.displayName - button text
 * @param {String} props.uri - button link
 * @param {String} [props.linkLocation] - used for analytics tracking
 * @returns {React.ReactElement}
 */
const CategoryButton = ({ displayName, uri, linkLocation }) => {
	if (
		!displayName ||
		!uri ||
		/^WOCHIT|REAL\sTIME|oneapp_eredcarpet_generalmills_2021|PARTNER\sAMAZON|PARTNER_AMAZON$/i.test(
			displayName
		)
	)
		return null;
	const analytics = {
		'data-analytics-linkname': createAnalyticsString(displayName),
		'data-analytics-linklocation': linkLocation
	};

	return (
		<a
			href={uri}
			{...(linkLocation && analytics)}
			className="category-button button is-secondary"
			data-hook="category__button"
			data-testid="category__button"
		>
			{parseHtml(displayName)}
		</a>
	);
};

CategoryButton.displayName = 'VideoDetail';

CategoryButton.propTypes = {
	displayName: string.isRequired,
	uri: string.isRequired,
	linkLocation: string
};

export default CategoryButton;
