import React from 'react';
import { number, object } from 'prop-types';
import striptags from 'striptags';
import { useArticleDetailContext } from '@/components/pages/article-detail/ArticleDetailContext';
import createAnalyticsString from '@/utils/createAnalyticsString';
import TextOnly from './TextOnly';
import Thumb from '@/components/thumb/Thumb';
import Button from '@/components/button/Button';
import { usePageContext } from '@/components/layout/page/PageContext';
import { withErrorBoundary } from '@/components/error/ErrorBoundary';
import './NbcuCheckoutItem.scss';

const staticAnalyticsData = {
	'data-analytics-linklocation': 'article-text-block-ecommerce-checkout',
	'data-analytics-linkname': 'checkout-buy-button',
	'data-analytics-linktype': 'button'
};

/**
 * @function NbcuCheckoutItem
 * @param {Object} [props.checkoutItem]
 * @param {Number} [props.segmentIndex]
 * @returns {React.ReactElement}
 */
const NbcuCheckoutItem = ({ checkoutItem = {}, segmentIndex }) => {
	const { edition, isAmp } = usePageContext();
	const { articleId, uri } = useArticleDetailContext();
	const {
		brandTitle,
		buyButtonText,
		channelKey,
		id,
		image,
		itemPrice,
		itemText,
		itemTitle,
		productKey,
		soldBy
	} = checkoutItem;

	// check for required data
	if (!channelKey || !id || !itemTitle || !itemPrice || !productKey) return null;

	const dataAttr = {
		...staticAnalyticsData,
		'data-analytics-articleid': articleId,
		'data-analytics-productid': id,
		'data-analytics-productname': createAnalyticsString(striptags(itemTitle)),
		'data-product-channel-key': channelKey,
		'data-product-key': productKey
	};

	return (
		<div
			className="nbcu-checkout"
			id={`usc-pdp?product-key=${productKey}&channel-key=${channelKey}`}
		>
			<div className="nbcu-checkout__content">
				<h4 className="nbcu-checkout__title" data-hook="checkout-title">
					{itemTitle}
				</h4>
				{(brandTitle || soldBy) && (
					<h6 className="nbcu-checkout__companies">
						{brandTitle && (
							<div className="nbcu-checkout__brand" data-hook="checkout-brand">
								{brandTitle}
							</div>
						)}
						{soldBy && (
							<div className="nbcu-checkout__soldby">
								Sold By{' '}
								<span
									className="nbcu-checkout__soldby-company"
									data-hook="checkout-soldby"
								>
									{soldBy}
								</span>
							</div>
						)}
					</h6>
				)}
				<TextOnly text={itemText} />
				<div className="columns nbcu-checkout__bottom is-tablet">
					<div className="column is-full-mobile">
						<Thumb
							type="1x1"
							width="400"
							height="400"
							thumbnail={image}
							withLink={false}
						/>
					</div>
					<div className="column nbcu-checkout__bottom-right is-full-mobile">
						<Button
							className="nbcu-checkout__cta"
							data={!isAmp ? dataAttr : undefined}
							href={
								isAmp
									? `${uri}#usc-pdp?product-key=${productKey}&channel-key=${channelKey}`
									: undefined
							}
							size="full"
						>
							<span>${itemPrice}</span>
							<span>{buyButtonText || 'Buy Now'}</span>
						</Button>

						{edition !== 'us' && (
							<div className="nbcu-checkout__note">
								<span>
									This item can only be shipped to addresses in the United States
								</span>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

NbcuCheckoutItem.propTypes = {
	checkoutItem: object,
	segmentIndex: number
};

NbcuCheckoutItem.displayName = 'NbcuCheckoutItem';

export default withErrorBoundary(NbcuCheckoutItem);
