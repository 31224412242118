import React, { Fragment, useContext, useState } from 'react';
import SvgImage from '@/components/icons/SvgImage';
import ChevronSvg from '@/images/chevron.svg';
import ArticleDetailContext from '@/components/pages/article-detail/ArticleDetailContext';
import { PageContext } from '@/components/layout/page/PageContext';
import CategoryButton from '@/components/category-button/CategoryButton';
import './ArticleCategories.scss';

const ArticleCategories = () => {
	const { categories } = useContext(ArticleDetailContext);
	const { isAmp } = useContext(PageContext);
	if (isAmp) return null;

	const [showCats, setShowCats] = useState(!!isAmp);
	const linkLocation = 'article-detail-tags';

	if (!categories) return null;

	return (
		<Fragment>
			<span
				data-hook="adp-categories-button"
				data-analytics-linkname="category-tag-dropdown"
				data-analytics-linklocation="article-detail-tags"
				role="button"
				tabIndex="0"
				className={`adp-cat__text ${showCats ? 'adp-cat__text--show' : ''}`}
				onClick={() => setShowCats(!showCats)}
				onKeyPress={() => setShowCats(!showCats)}
			>
				Tags
				<SvgImage className="adp-cat__chevron" image={ChevronSvg} />
			</span>

			<div
				data-hook="apd-categories-list"
				className={`adp-cat__list ${showCats ? 'adp-cat__list--show' : ''}`}
			>
				{categories.map(({ displayName, uri, id }) => (
					<CategoryButton key={id} {...{ displayName, uri, linkLocation }} />
				))}
			</div>
		</Fragment>
	);
};

ArticleCategories.displayName = 'ArticleCategories';

export default ArticleCategories;
