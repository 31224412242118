import React from 'react';
import { any } from 'prop-types';
import { IS_LOCAL, APP_HOSTNAME } from '@/config/config';

/**
 * @function AmpList
 * @description wraps children with amp-list and amp-mustache template
 * @description pulls json data from /amp/country-ad-slots/:county? route
 * @description AMP_GEO is a variable provided by amp-state and amp-geo
 * @description provides child amp-ad element with data to fill data-slot={{slot}}
 * @description population of {{slot}} happens after render by amp
 * @param {React.ReactElement} children
 * @returns {React.ReactElement}
 */
const AmpList = ({ children }) => {
	if (!children) return null;

	// amp needs the absulute https path to the src
	// we need to provide the absolute path to the route
	const hostPath = IS_LOCAL ? '' : `https://${APP_HOSTNAME}`;

	return (
		<amp-list layout="fill" src={`${hostPath}/amp/country-ad-slots/AMP_GEO`}>
			<template type="amp-mustache">{children}</template>
		</amp-list>
	);
};

AmpList.propTypes = {
	children: any.isRequired
};

AmpList.displayName = 'AmpList';

export default AmpList;
