import { createContext, useContext } from 'react';

const ArticleDetailContext = createContext({
	articleId: '',
	categories: [],
	enableVideoPlaylist: false,
	segments: [],
	social: {},
	uri: '',
	hasEcommerce: false
});

export const useArticleDetailContext = () => useContext(ArticleDetailContext);

export default ArticleDetailContext;
