import React, { Fragment, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageContext } from '@/components/layout/page/PageContext';
import TextOnly from './TextOnly';
import { string, object } from 'prop-types';
import { formatYoutubeEmbed } from './formatYoutubeEmbed';
/**
 * @function AmpSocialBlock
 * @description displays social cotent using amp specific elements
 * @param {Object} props
 * @param {Object} props.socialContent - object containing platform specific info
 * @param {string} props.socialContent.id - tweetid / videoid / shortcode
 * @param {string} props.socialContent.type - twitter / youtube / instagram
 * @returns {React.ReactElement}
 */
const AmpSocialBlock = ({ socialContent }) => {
	if (!socialContent?.type || !socialContent?.id) return null;

	const { type, id } = socialContent;

	if (type === 'twitter') {
		return (
			<amp-twitter
				width="375"
				height="472"
				layout="responsive"
				data-tweetid={id}
			></amp-twitter>
		);
	}

	if (type === 'youtube') {
		return (
			<amp-youtube
				data-videoid={id}
				layout="responsive"
				width="480"
				height="270"
			></amp-youtube>
		);
	}

	if (type === 'instagram') {
		return (
			<amp-instagram
				data-shortcode={id}
				data-captioned
				width="400"
				height="400"
				layout="responsive"
			></amp-instagram>
		);
	}
	return null;
};

AmpSocialBlock.propTypes = {
	socialContent: object.isRequired
};

AmpSocialBlock.displayName = 'AmpSocialBlock';

/**
 * @function SocialBlock
 * @description display non amp social content
 * @param {Object} props
 * @param {String} props.html
 * @returns {React.ReactElement}
 */
const SocialBlock = ({ html }) => {
	/* Using dangerouslySetInnerHTML instead of parseHtml here.
		Some of the code needed to load the instagram and twitter embeds
		was getting stripped out.
	*/
	const formatedStr = formatYoutubeEmbed(html);
	return formatedStr ? (
		<div
			suppressHydrationWarning
			data-hook="socail-content"
			className="article-detail__social-content"
			dangerouslySetInnerHTML={{ __html: formatedStr }}
		/>
	) : null;
};

SocialBlock.propTypes = {
	html: string.isRequired
};

SocialBlock.displayName = 'SocialBlock';

/**
 * @function SocialContent
 * @description displays embed content from instagram, twitter and youtube
 * @param {Object} props
 * @param {String} props.html
 * @param {String} props.socialContent - object containing platform(twitter/insta/youtuve) specific info
 * @param {String} [props.text]
 * @returns {React.ReactElement}
 */
const SocialContent = ({ socialContent, text }) => {
	if (!socialContent?.oembedHtml) return null;
	const { isAmp } = useContext(PageContext);
	return (
		<Fragment>
			{isAmp ? (
				<Fragment>
					<Helmet
						script={[
							{
								src: 'https://cdn.ampproject.org/v0/amp-twitter-0.1.js',
								'custom-element': 'amp-twitter',
								async: undefined
							},
							{
								src: 'https://cdn.ampproject.org/v0/amp-instagram-0.1.js',
								'custom-element': 'amp-instagram',
								async: undefined
							},
							{
								src: 'https://cdn.ampproject.org/v0/amp-youtube-0.1.js',
								'custom-element': 'amp-youtube',
								async: undefined
							}
						]}
					/>
					<AmpSocialBlock socialContent={socialContent} />
				</Fragment>
			) : (
				<SocialBlock html={socialContent.oembedHtml} />
			)}
			{text && <TextOnly text={text} />}
		</Fragment>
	);
};

SocialContent.propTypes = {
	socialContent: object.isRequired,
	text: string
};

SocialContent.displayName = 'SocialContent';

export default SocialContent;
