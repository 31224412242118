import { gql } from '@apollo/client';

export default gql`
	query ARTICLE(
		$id: ID!
		$edition: EDITIONKEY!
		$sanitizer: SANITIZATION_TYPE
		$limit: Limit
		$source: PUBLISHABLE_SOURCE
	) {
		article(id: $id, source: $source) {
			title
			shortTitle
			seoHeadline
			subhead
			autoPlayTopVideo
			pageMetaData(edition: $edition) {
				defaultUrl
				canonicalUrl
				redirectUrl
				hreflang {
					uri
					edition
				}
			}
			uri(edition: $edition)
			edition
			publishDate
			lastModDate
			snipe
			enableVideoPlaylist
			thumbnail(edition: $edition) {
				uri
				title
			}
			translators
			authors {
				id
				fullName
				uri
			}
			publishedByUsers {
				id
				fullName
			}
			categories(filterWorkflow: true) {
				id
				name
				displayName(edition: $edition)
				uri(edition: $edition)
				title
				key
			}
			branding {
				brandingType
				displayText
				disclosureText
				ads {
					adKeywords
				}
				adTracking {
					advertiser
				}
			}
			show(edition: $edition) {
				uri(edition: $edition)
				logo(edition: $edition) {
					id
					title
					uri
					sourceWidth
					sourceHeight
				}
				tuneIn
				youtubeAccountUrl
				facebookAccountUrl
				instagramAccountUrl
				twitterAccountUrl
				brandHexColor1
			}
			segments(sanitizer: $sanitizer) {
				cta {
					id
					type
					uri(edition: $edition)
					title
					thumbnail(edition: $edition) {
						id
						title
						uri
					}
				}
				uri
				socialContent {
					id
					type
					oembedHtml
				}
				gallery {
					id
					title
					uri(edition: $edition)
					thumbnail(edition: $edition) {
						id
						title
						uri
					}
					galleryitems(filter: { limit: $limit }) {
						totalCount
						nodes {
							caption
							title
							image(edition: $edition) {
								id
								title
								uri
								sourceWidth
								sourceHeight
								agency
							}
						}
					}
				}
				text
				html
				type
				poll {
					id
					title
					isExpired
					isCaptchaEnabled
					isShowResult
					questions {
						questionId
						questionText
						choices {
							choiceId
							choiceText
							resultPercentage
							isWinner
						}
					}
					submitText
					submitMessage
					submitTitle
				}
				image(edition: $edition) {
					uri
					title
					id
					sourceWidth
					sourceHeight
					uri
					agency
				}
				video {
					id
					publicId
					title
					videoUri
					duration
					description
					publishDate
					expirationDate
					webVttUri
					thumbnail {
						id
						title
						uri
					}
					hdThumbnail {
						id
						title
						uri
					}
				}
				productOffer {
					id
					image(edition: $edition) {
						id
						uri
					}
					itemTitle
					itemText
					productLinks {
						currentPrice
						originalPrice
						linkUri
						linkText
					}
				}
				checkoutItem {
					brandTitle
					buyButtonText
					channelKey
					id
					itemTitle
					image(edition: $edition) {
						title
						uri
						sourceHeight
						sourceWidth
					}
					itemText
					itemPrice
					productKey
					soldBy
				}
				checkoutCollectionKey
			}
		}
	}
`;
