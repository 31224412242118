import React from 'react';
import { usePageContext } from '@/components/layout/page/PageContext';
import { string } from 'prop-types';

/**
 * @function HtmlOnly
 * @param {String} html - displays html content
 * @returns {React.ReactElement}
 */
const HtmlOnly = ({ html }) => {
	const { isAmp } = usePageContext();
	return html && !isAmp ? (
		<div
			data-hook="html-only"
			dangerouslySetInnerHTML={{
				__html: html
			}}
		></div>
	) : null;
};

HtmlOnly.propTypes = {
	html: string.isRequired
};

HtmlOnly.displayName = 'HtmlOnly';

export default HtmlOnly;
