import React from 'react';
import { any, string, object, bool, func } from 'prop-types';

/**
 * @function Button
 * @description provides a basic button styling configuration
 * @param {Object} [props.data] - for data attributes (analytics, etc.)
 * @param {Object} [props.children]
 * @param {String} [props.className]
 * @param {String} [props.href] - use to make an anchor tag instead of <button>
 * @param {Boolean} [props.isInactive] button takes inactive color scheme
 * @param {Boolean} [props.isSecondary] button takes secondary color scheme
 * @param {Function} [props.onClick] button handler event
 * @param {String} [props.size] options are small, medium, large, or tag
 * @returns {React.ReactElement}
 */
const Button = ({ data, children, className, href, isSecondary, isInactive, onClick, size }) => {
	let sizeClass = 'button--md';

	switch (size) {
		case 'full':
			sizeClass = 'button--full';
			break;
		case 'small':
			sizeClass = 'button--sm';
			break;
		case 'large':
			sizeClass = 'button--lrg';
			break;
		case 'tag':
			sizeClass = 'button--tag';
	}

	return href ? (
		<a
			{...data}
			className={`button
				${className || ''}
				${sizeClass}
				${isSecondary ? 'is-secondary' : ''}
				${isInactive ? 'is-inactive' : ''}
			`}
			href={href}
		>
			{children || ''}
		</a>
	) : (
		<button
			{...data}
			className={`button
				${className || ''}
				${sizeClass}
				${isSecondary ? 'is-secondary' : ''}
				${isInactive ? 'is-inactive' : ''}
			`}
			onClick={onClick}
		>
			{children || ''}
		</button>
	);
};

Button.defaultProps = {
	isInactive: false,
	isSecondary: false,
	size: 'medium'
};

Button.propTypes = {
	data: object,
	children: any,
	className: string,
	href: string,
	isInactive: bool,
	isSecondary: bool,
	onClick: func,
	size: string
};

Button.displayName = 'Button';

export default Button;
